import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import UserService from "../service/UserService";
import './ChangePassword.css';
import showIcon from '../../assets/eye_icon.png';  
import hideIcon from '../../assets/eye_slash_icon.png';  
import cmxLogo from '../../assets/cmxlogo-removebg-preview.png';
// import mhcLogo from '../../assets/mhc_icon.png';
import changePassVector from '../../assets/change_pass_icon.png';

function ChangePassword() {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showNewPassword, setShowNewPassword] = useState(false); 
  const [showConfirmPassword, setShowConfirmPassword] = useState(false); 
  const [message, setMessage] = useState('');
  const [userId, setUserId] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const storedUserId = localStorage.getItem('userid');
    const pwdType = localStorage.getItem('pwdType'); 
    setUserId(storedUserId);

    if (pwdType !== 'temporary') {
      navigate('/dashboard'); // Redirect if password is already permanent
    }

  }, []);

  const handlePasswordChange = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await UserService.updatePassword(newPassword, token);
    setMessage(response.message || "Password updated successfully!");

    // Update pwdType in localStorage
    localStorage.setItem('pwdType', 'permanent');

    setTimeout(() => {
      navigate('/login');
    }, 3000);
  } catch (error) {
    setMessage(error.message);
  }
};

  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
    setMessage(''); // Clear any previous message when typing
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    setMessage(''); // Clear any previous message when typing

    // Check if passwords match, otherwise show a message
    if (e.target.value && newPassword && e.target.value !== newPassword) {
      setMessage("Passwords do not match.");
    }
  };

  return (
    <div className="change-pass-page-wrapper">
      <div className="change-pass-auth-container">
        <div className="change-pass-form-container">
          <div className="change-pass-logo-container">
            <img src={cmxLogo} alt="CMX Logo" />
            {/* <img 
              src={mhcLogo} 
              alt="MHC Logo" 
              style={{ marginLeft: '10px', width: 'auto', height: '70%' }}
            /> */}
          </div>

          <h1 className="change-pass-title" style={{ color: 'black', fontSize: '24px', fontWeight: '800', marginLeft: '20px', marginBottom: '30px' }}>Change Password</h1>
          <p className="change-pass-user-id" style={{ marginRight: '185px', fontSize: '16px', fontWeight: '800' }}>
            User ID: <span className="highlight">{userId}</span>
          </p>

          <div className="change-pass-form-group">
            <label style={{ fontWeight: '800' }}>Enter new password:</label>
            <div className="change-pass-input-wrapper">
              <input
                type={showNewPassword ? "text" : "password"}
                value={newPassword}
                onChange={handleNewPasswordChange}
                required
              />
              <button
                type="button"
                className="change-pass-toggle-password-btn"
                onClick={() => setShowNewPassword((prev) => !prev)}
              >
                <img 
                  src={showNewPassword ? hideIcon : showIcon} 
                  alt={showNewPassword ? "Hide password" : "Show password"} 
                  className="change-pass-eye-icon"
                />
              </button>
            </div>
          </div>

          <div className="change-pass-form-group">
            <label style={{ fontWeight: '800' }}>Confirm password:</label>
            <div className="change-pass-input-wrapper">
              <input
                type={showConfirmPassword ? "text" : "password"}
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
                required
              />
              <button
                type="button"
                className="change-pass-toggle-password-btn"
                onClick={() => setShowConfirmPassword((prev) => !prev)}
              >
                <img 
                  src={showConfirmPassword ? hideIcon : showIcon} 
                  alt={showConfirmPassword ? "Hide password" : "Show password"} 
                  className="change-pass-eye-icon"
                />
              </button>
            </div>
          </div>

          {message && (
            <p className={message.toLowerCase().includes("successfully") ? "change-pass-message" : "change-pass-error"}>
              {message}
            </p>
          )}

          <button 
            className="change-pass-btn" 
            onClick={handlePasswordChange} 
            disabled={newPassword !== confirmPassword || !newPassword}
          >
            Update Password
          </button>

        </div>

        <div className="change-pass-task-tracker-icon-container">
          <img 
            src={changePassVector} 
            alt="changePassVector" 
            style={{ width: '400px', height: 'auto', marginRight: '40px' }}
          />
        </div>
      </div>
    </div>
  );
}

export default ChangePassword;
