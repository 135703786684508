import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import axios from 'axios';
import Modal from 'react-modal';
import Sidebar from '../../common/Sidebar';
import { SERVER_URL } from '../../lib/constants';
import './AddEscalationInfo.css';

const date = new Date();
const formattedDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')} ${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}:${String(date.getSeconds()).padStart(2, '0')}`;


const INITIAL_FORM_DATA = {
    escalationDate: new Date().toISOString().split('T')[0],
    escalationID: '(Auto Generated)',
    account: '',
    lob: '',
    task: '',
    site: '',
    oic: '',
    oicEmail: '',
    clientCategory: '',
    escalationType: '',
    escalationDetails: '',
    validity: '',
    criticality: '',
    reportSubmitted: '',
    reportSubmittedDate: null,
    status: 'Open',
    resolvedDate: null,
    accountCode: '',
    actionTaken: '',
    resolutionStatus: '',
    dateLastUpdated: formattedDate
  };

const AddEscalationInfo = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const escalationData = location.state?.escalationData || {};
  
  const [formData, setFormData] = useState({...INITIAL_FORM_DATA, ...escalationData });
  const [maxId, setMaxId] = useState('001');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [isSuccessful, setIsSuccessful] = useState(false); // Track if the operation was successful
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [accounts, setAccounts] = useState([]); // New state for storing accounts
  const [lobOptions, setLobOptions] = useState([]);
  const [taskOptions, setTaskOptions] = useState([]);
  const [oicOptions, setOicOptions] = useState([]);
  const [siteOptions, setSiteOptions] = useState([]);


  // Fetch Account Details
  useEffect(() => {
    const fetchAccounts = async () => {
      try {
        const response = await axios.get(`${SERVER_URL}/api/accountDetails`);
        setAccounts(response.data); // Store fetched accounts in state
      } catch (error) {
        console.error('Error fetching accounts:', error);
      }
    };
  
    fetchAccounts();
  }, []);

  // Populate LOB based on the ACCOUNT selection
    useEffect(() => {
      if (formData.account) {
        const filteredLobs = accounts
          .filter((acc) => acc.ACCOUNT.toLowerCase() === formData.account.toLowerCase())
          .map((acc) => acc.LOB.toUpperCase()) // Convert each LOB to uppercase
          .filter((value, index, self) => self.indexOf(value) === index); // Remove duplicates
    
        setLobOptions(filteredLobs);
        setFormData((prevData) => ({ ...prevData, lob: '' })); // Reset LOB to force user selection
      } else {
        setLobOptions([]); // Clear options if no account is selected
        setFormData((prevData) => ({ ...prevData, lob: '' })); // Clear selected LOB
      }
    }, [formData.account, accounts]);


  // Populate TASK based on the LOB selection
    useEffect(() => {
      if (formData.lob) {
        const filteredTasks = accounts
          .filter((acc) => acc.LOB && acc.LOB.toLowerCase() === formData.lob.toLowerCase())
          .map((acc) => acc.TASK)
          .filter((value, index, self) => self.indexOf(value) === index); // Remove duplicates
    
        setTaskOptions(filteredTasks);
        setFormData((prevData) => ({ ...prevData, task: '' })); // Reset Task to force user selection
      } else {
        setTaskOptions([]); // Clear options if no LOB is selected
        setFormData((prevData) => ({ ...prevData, task: '' })); // Clear selected Task
      }
    }, [formData.lob, accounts]);

 // Lookup Account Code
    useEffect(() => {
      if (formData.account && formData.lob && formData.task) {
        const matchingAccount = accounts.find((acc) => 
          acc.ACCOUNT && acc.LOB && acc.TASK && // Ensure these fields are not undefined
          acc.ACCOUNT.toLowerCase() === formData.account.toLowerCase() &&
          acc.LOB.toLowerCase() === formData.lob.toLowerCase() &&
          acc.TASK.toLowerCase() === formData.task.toLowerCase()
        );

        if (matchingAccount) {
          setFormData((prevData) => ({ ...prevData, accountCode: matchingAccount.ACCOUNTCODE }));
        } else {
          setFormData((prevData) => ({ ...prevData, accountCode: '' })); // Clear if no match found
        }
      } else {
        setFormData((prevData) => ({ ...prevData, accountCode: '' })); // Clear if any field is missing
      }
    }, [formData.account, formData.lob, formData.task, accounts]);


// Automatically select Site based on Account, LOB, and Task selections
useEffect(() => {
  if (formData.account && formData.lob && formData.task) {
    const matchingAccount = accounts.find((acc) => 
      acc.ACCOUNT && acc.LOB && acc.TASK &&
      acc.ACCOUNT.toLowerCase() === formData.account.toLowerCase() &&
      acc.LOB.toLowerCase() === formData.lob.toLowerCase() &&
      acc.TASK.toLowerCase() === formData.task.toLowerCase()
    );

    if (matchingAccount) {
      setFormData((prevData) => ({ ...prevData, site: matchingAccount.SITE || '' }));
    } else {
      setFormData((prevData) => ({ ...prevData, site: '' })); // Clear if no match found
    }
  } else {
    setFormData((prevData) => ({ ...prevData, site: '' })); // Clear if any field is missing
  }
}, [formData.account, formData.lob, formData.task, accounts]);


  // Fetch the max ID from the server when the component loads  
  useEffect(() => {
    const fetchMaxId = async () => {
      try {
        const response = await axios.get(`${SERVER_URL}/api/escalMaxId`);
        const incrementedId = String(response.data.maxId).padStart(3, '0');  // Corrected to 'maxId' here
        setMaxId(incrementedId);
      } catch (error) {
        console.error('Error fetching max ID:', error);
      }
    };
  
    fetchMaxId();
  }, []);

  // Effect to generate Escalation ID based on criteria
    useEffect(() => {
        const generateEscalationID = () => {
        const { site, account, task, escalationDate } = formData;
    
        if (site, account && task && escalationDate && maxId) {

            const sitePrefix = site.substring(0, 2).toUpperCase();

            // Get the first two letters of the "account" field
            const accountPrefix = account.substring(0, 1).toUpperCase();
    
            // Get the first two letters of the "lob" field
            const taskPrefix = task.substring(0, 2).toUpperCase();
    
            // Extract year and month from escalationDate (formatted as YYYY-MM-DD)
            const [year, month] = escalationDate.split('-');
            const yearShort = year.substring(2);
            const nextId = parseInt(maxId, 10) + 1;
            const formattedId = `${nextId}`.padStart(3, '0');

    
            // Construct the Escalation ID
            const generatedEscalationID = `${sitePrefix}-${accountPrefix}${taskPrefix}-${yearShort}${month}-${formattedId}`;
            
            // Update the formData with the generated EscalationID
            setFormData(prevData => ({ ...prevData, escalationID: generatedEscalationID }));
        }
        };
    
        generateEscalationID(); // Call the function when component mounts or relevant data changes
    }, [formData.site, formData.account,formData.task, formData.escalationDate, maxId]);

    // Fetch OIC List for Dropdown
    useEffect(() => {
      const fetchOicList = async () => {
        try {
          const response = await axios.get(`${SERVER_URL}/api/oicList`);
          setOicOptions(response.data); // Store full data to access EMAIL
        } catch (error) {
          console.error('Error fetching OIC list:', error);
        }
      };
    
      fetchOicList();
    }, []);

    //Fetch and identify email based on OIC dropdown selection
    useEffect(() => {
      if (formData.oic) {
        const selectedOic = oicOptions.find((oic) => oic.EMPNAME === formData.oic);
        if (selectedOic) {
          setFormData((prevData) => ({ ...prevData, oicEmail: selectedOic.EMAIL }));
        } else {
          setFormData((prevData) => ({ ...prevData, oicEmail: '' })); // Clear if no match found
        }
      } else {
        setFormData((prevData) => ({ ...prevData, oicEmail: '' })); // Clear if OIC is deselected
      }
    }, [formData.oic, oicOptions]);


    //To ensure all fields should be inputted
    // Validate form fields
    const validateForm = () => {
      const requiredFields = {
        escalationDate: 'Escalation Date',
        clientCategory: 'Client Category',
        escalationType: 'Escalation Type',
        site: 'Site',
        account: 'Account',
        lob: 'LOB',
        task: 'Task',
        oic: 'OIC',
        escalationDetails: 'Escalation Details',
        criticality: 'Criticality',
        status: 'Escalation Status',
      };
    
      // Add additional required fields if status is Closed
      if (formData.status === "Closed") {
        requiredFields.validity = 'Validity';
        requiredFields.reportSubmitted = 'Report Submitted';
        requiredFields.actionTaken = 'Action Taken';
        requiredFields.resolutionStatus = 'Resolution Status';
        requiredFields.resolvedDate = 'Resolved Date';
      }
    
      // Add additional required field if reportSubmitted is Yes
      if (formData.reportSubmitted === "Yes") {
        requiredFields.reportSubmittedDate = 'Report Submitted Date';
      }
    
      const missingFields = Object.keys(requiredFields).filter(field => !formData[field]);
    
      if (missingFields.length > 0) {
        const missingFieldNames = missingFields.map(field => requiredFields[field]).join(', ');
        setError(`Please fill out the following required fields: [${missingFieldNames}]`);
        return false;
      }
    
      setError('');
      return true;
    };


      // Handle form inputs change
      const handleInputChange = (e) => {
        const { name, value } = e.target;
        
        setFormData(prevData => {
          // Check if resolutionStatus is changed and not equal to "Pending"
          if (name === "resolutionStatus" && value !== "Pending") {
            return {
              ...prevData,
              [name]: value,
              status: "Closed" // Automatically set status to "Closed"
            };
          }
          
          return {
            ...prevData,
            [name]: value
          };
        });
      };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setLoading(true);

    try {
      await axios.post(`${SERVER_URL}/addEscalation`, formData); // Await the response
      setModalMessage(`Escalation added successfully with ID: ${formData.escalationID}`);
      setIsSuccessful(true);
      setModalIsOpen(true);
      setFormData(INITIAL_FORM_DATA);
    } catch (error) {
      console.error('Error adding escalation:', error);
      setModalMessage('Failed to add escalation. Please try again.');
      setIsSuccessful(false);
      setModalIsOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    if (isSuccessful) {
      navigate('/Escalations'); // Navigate only if successful
    }
  };


  const reqD1 = () => {
    if (formData.status === "Closed") {
      return "*";
    } else {
      return "";
    }
  };

  const reqD2 = () => {
    if (formData.reportSubmitted === "Yes") {
      return "*";
    } else {
      return "";
    }
  };

  return (
    <div className="sidebar-and-main-container">
      <Sidebar />
      <div className="addescalation-info-container">
        <h2 className="form-title">Add Escalation Details</h2>
        <div className="save-update-cancel-button-container">
          <button type="submit" className="save-button" onClick={handleSubmit} disabled={loading}>
            {loading ? 'Saving...' : 'Save'}
          </button>
          <button type="button" className="cancel-button" onClick={handleCancel}>Cancel</button>
        </div>
        <div className="addescalation-form-spacer">
        {error && <div className="error-message">{error}</div>}

        </div>
        <form className="addescalation-info-form" >
        <div className="addescalation-form-column">
            {/* Column 1 */}
            <div className="addescalation-form-spacer"></div>
            <div className="addescalation-form-group">
              <label>Escalation Date <span style={{ color: 'red' }}>*</span></label>
              <input type="date" name="escalationDate" value={formData.escalationDate} onChange={handleInputChange}
              />
            </div>

            <div className="addescalation-form-group">
              <label>Account <span style={{ color: 'red' }}>*</span></label>
              <select name="account" value={formData.account} onChange={handleInputChange}>
                <option value="" disabled>(Select)</option>
                {[...new Set(accounts.map(account => account.ACCOUNT.toUpperCase()))]
                  .sort()
                  .map((uniqueAccount, index) => (
                    <option key={index} value={uniqueAccount}>{uniqueAccount}</option>
                  ))}
              </select>
            </div>





          </div>

          <div className="addescalation-form-column">
            {/* Column 2 */}
            <div className="addescalation-form-spacer"></div>
            <div className="addescalation-form-group">
              <label>Client Catergory <span style={{ color: 'red' }}>*</span></label>
              <select name="clientCategory" value={formData.clientCategory} onChange={handleInputChange}>
                <option value="" disabled selected>(Select)</option>
                <option value="Assembly">Assembly</option>
                <option value="Non Assembly">Non Assembly</option>
              </select>
            </div>
            <div className="addescalation-form-group">
              <label>LOB <span style={{ color: 'red' }}>*</span></label>
              <select name="lob" value={formData.lob} onChange={handleInputChange}>
                <option value="" disabled>(Select)</option>
                {lobOptions.map((lob, index) => (
                  <option key={index} value={lob}>{lob}</option>
                ))}
              </select>
            </div>


          </div>

          <div className="addescalation-form-column">
            {/* Column 3 */}
            <div className="addescalation-form-spacer"></div>
            <div className="addescalation-form-group">
              <label>Escalation ID</label>
              <textarea name="escalationID" rows="1" className="addescalation-input" value={formData.escalationID} onChange={handleInputChange} disabled></textarea>
            </div>
            <div className="addescalation-form-group">
              <label>Task <span style={{ color: 'red' }}>*</span></label>
              <select name="task" value={formData.task} onChange={handleInputChange}>
                <option value="" disabled>(Select)</option>
                {taskOptions.map((task, index) => (
                  <option key={index} value={task}>{task}</option>
                ))}
              </select>
            </div>



          </div>

          <div className="addescalation-form-column">
            {/* Column 4 */}
            <div className="addescalation-form-spacer"></div>
            <div className="addescalation-form-group"></div>
            <div className="addescalation-form-group">
            <label>Site <span style={{ color: 'red' }}>*</span></label>
              <select name="site" value={formData.site} onChange={handleInputChange}>
                <option value="" disabled>(Select)</option>
                <option value="PH">PH</option>
                <option value="DR">DR</option>
                <option value="Blended">Blended</option>
              </select>
            </div>
          </div>
          </form>

          <form className="addescalation-info-formnotes" >
          <div className="addescalation-form-column">
          <div className="addescalation-form-notes">
              <label>Escalation Details <span style={{ color: 'red' }}>*</span></label>
              <textarea name="escalationDetails" rows="10" className="addescalation-textarea" value={formData.escalationDetails} onChange={handleInputChange}></textarea>
            </div>
            </div>
          </form>

          <form className="addescalation-info-form" >
          <div className="addescalation-form-group">
              <label>Escalation Type <span style={{ color: 'red' }}>*</span></label>
              <select name="escalationType" value={formData.escalationType} onChange={handleInputChange}>
              <option value="" disabled selected>(Select)</option>
                <option value="Billing Issue">Billing Issue</option>
                <option value="Executive Escalation">Executive Escalation</option>
                <option value="Headcount Reduction">Headcount Reduction</option>
                <option value="Performance Issue">Performance Issue</option>
                <option value="Leadership Concerns">Leadership Concerns</option>
                <option value="Task / Output Feedback">Task / Output Feedback</option>
                <option value="Termination of Service">Termination of Service</option>
                <option value="Unresolved Issues">Unresolved Issues</option>
                <option value="Others">Others</option>
              </select>
            </div>
            <div className="addescalation-form-group">
              <label>Criticality <span style={{ color: 'red' }}>*</span></label>
              <select name="criticality" value={formData.criticality} onChange={handleInputChange}>
                <option value="" disabled selected>(Select)</option>
                <option value="Low">Low</option>
                <option value="Medium">Medium</option>
                <option value="High">High</option>
                <option value="Critical">Critical</option>
              </select>
            </div>
            <div className="addescalation-form-group">
              <label>OIC / Account Manager <span style={{ color: 'red' }}>*</span></label>
              <select name="oic" value={formData.oic} onChange={handleInputChange}>
                <option value="" disabled>(Select)</option>
                {oicOptions.slice().sort((a, b) => a.EMPNAME.localeCompare(b.EMPNAME)).map((oic, index) => (
                  <option key={index} value={oic.EMPNAME}>{oic.EMPNAME}</option>
                ))}
              </select>
            </div>
          </form>

          <form className="addescalation-info-form" >
            <div className="addescalation-form-group">
              <label>Validity <span style={{ color: 'red' }}>{reqD1()}</span></label>
              <select name="validity" value={formData.validity} onChange={handleInputChange}>
                <option value="" disabled selected>(Select)</option>
                <option value="Valid">Valid</option>
                <option value="Invalid">Invalid</option>
              </select>
            </div>
            <div className="addescalation-form-group">
              <label>Report Submitted ? <span style={{ color: 'red' }}>{reqD1()}</span></label>
              <select name="reportSubmitted" value={formData.reportSubmitted} onChange={handleInputChange}>
                <option value="" disabled selected>(Select)</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>
            <div className="addescalation-form-group">
              <label>Report Submission Date <span style={{ color: 'red' }}>{reqD2()}</span></label>
              <input type="date" name="reportSubmittedDate" value={formData.reportSubmittedDate} onChange={handleInputChange}
              />
            </div>
          </form>
          <form className="addescalation-info-formnotes" >
          <div className="addescalation-form-column">
          <div className="addescalation-form-notes">
              <label>Actions Taken</label>
              <textarea name="actionTaken" rows="10" className="addescalation-textarea" value={formData.actionTaken} onChange={handleInputChange}></textarea>
            </div>
            </div>
          </form>
          <form className="addescalation-info-form" >
          <div className="updateEscalation-form-group">
              <label>Resolution Status</label>
              <select name="resolutionStatus" value={formData.resolutionStatus} onChange={handleInputChange}>
                <option value="Pending" selected>Pending</option>
                <option value="Resolved">Resolved</option>
                <option value="Unresolved">Unresolved</option>
              </select>
            </div>
            <div className="addescalation-form-group">
              <label>Escalation Status <span style={{ color: 'red' }}>*</span></label>
              <select name="status" value={formData.status} onChange={handleInputChange}>
                <option value="Open">Open</option>
                <option value="Closed">Closed</option>
              </select>
            </div>
            <div className="addescalation-form-group">
              <label>Date Closed</label>
              <input type="date" name="resolvedDate" value={formData.resolvedDate} onChange={handleInputChange}
              />
            </div>
          </form>

      {/* Modal Implementation */}
        <Modal
              isOpen={modalIsOpen}
              onRequestClose={closeModal}
              contentLabel="Message Modal"
              className="modal"
              overlayClassName="overlay"
            >
              <h2>{modalMessage}</h2>
              <div className="newcancel-btn"> {/* Correctly wrap buttons in this div */}
              <button
            className="mdlcreate-button"
            onClick={() => {
                setModalIsOpen(false);
                window.location.reload(); // Reload the page when "Create New" is clicked
            }}
              >
                  Create New
              </button>
                <button className="mdlclose-button" onClick={closeModal}>Close</button>
              </div>
        </Modal>
  </div>
</div>
);
};

export default AddEscalationInfo;