// UpdateClient.jsx
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import axios from 'axios';
import Sidebar from '../../common/Sidebar';
import './UpdateClient.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { SERVER_URL } from '../../lib/constants';

const UpdateClient = () => {
  const navigate = useNavigate();
  const location = useLocation(); // Access the data passed from the Dashboard
  const clientData = location.state?.clientData || {};  // Retrieve the client data from the state
  const [error, setError] = useState('');
  const [isTerminationDateEditable, setIsTerminationDateEditable] = useState(false);
  

  // Define formatDate function before using it in formData
  const formatDate = (date) => {
    if (!date) return '';
    const d = new Date(date);
    const month = `${d.getMonth() + 1}`.padStart(2, '0');
    const day = `${d.getDate()}`.padStart(2, '0');
    const year = d.getFullYear();
    return `${year}-${month}-${day}`;
  };

  // Initialize formData with existing clientData
  const [formData, setFormData] = useState({
    // sequenceID: clientData.SequenceID || '', 
    effectiveDate: formatDate(clientData.EFFECTIVEDATE),
    accountCode: clientData.ACCOUNTCODE || null,
    qbAccount: clientData.QBACCOUNT || null,
    account: clientData.ACCOUNT || null,
    lob: clientData.LOB || null,
    task: clientData.TASK || null,
    msaDate: formatDate(clientData.MSA_DATE) || null,
    liveDate: formatDate(clientData.LIVE_DATE) || null,
    staffingModel: clientData.STAFFINGMODEL || '',
    drfte: clientData.DRFTE || null,
    phfte: clientData.PHFTE || null,
    dailyWorkHrs: clientData.DAILYWORKHRS || null,
    holidayHrs: clientData.HOLIDAYHRS || null,
    regularRate: clientData.REGULARRATE || null,
    premiumRate: clientData.PREMIUMRATE || null,
    depositFee: clientData.DEPOSITFEE || null,
    depositFeeWaived: clientData.DEPOSITFEEWAIVED || 'No',
    setupFee: clientData.SETUPFEE || null,
    setupFeeWaived: clientData.SETUPFEEWAIVED || 'No',
    extraMonitorFeePerUnit: clientData.EXTRAMONITORFEEPERUNIT || null,
    extraMonitorQty: clientData.EXTRAMONITORQTY || null,
    phoneLineFeePerFTEPerMonth: clientData.PHONELINEFEEPERFTEPERMONTH || null,
    billingCycle: clientData.BILLINGCYCLE || 'Monthly',
    status: clientData.STATUS || null,
    busAddress: clientData.BUSADDRESS || null,
    state: clientData.STATE || null,
    contact1: clientData.CONTACT1 || null,
    contactNo1: clientData.CONTACTNO1 || null,
    contact2: clientData.CONTACT2 || null,
    contactNo2: clientData.CONTACTNO2 || null,
    salesperson: clientData.SALESPERSON || null,
    notes: clientData.NOTES|| null,
    termDate: formatDate(clientData.TERMDATE) || null,
    site: clientData.NOTES|| null
  });

  console.log(formData.termDate)

  const [isEditable, setIsEditable] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === 'status') {
      setIsTerminationDateEditable(value === 'Discontinued');

      setFormData(prevData => ({
        ...prevData,
        status: value,
      }));
      
    } else if (name === 'account') {
      setFormData(prevData => ({
        ...prevData,
        [name]: value,
        qbAccount: value // Automatically set qbAccount to match account
      }));
    } else {
      setFormData(prevData => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

const getMessageClass = () => {
  return error === "Update Successful" ? "success-message" : "error-message";
};

const validateForm = () => {
  const missingFields = [];

  if (formData.status === "Prospect Client") {
    if (!formData.effectiveDate) missingFields.push("Effective Date");
    if (!formData.account) missingFields.push("Account");
    if (!formData.site) missingFields.push("Site");
    if (!formData.lob) missingFields.push("LOB");
    if (!formData.task) missingFields.push("Task");
    if (!formData.contact1) missingFields.push("Contact 1");
    if (!formData.contactNo1) missingFields.push("Contact No 1");

    if (missingFields.length > 0) {
      setError(`Fill up all required fields (*): ${missingFields.join(", ")}`);
      return false;
    }
  }

  if (formData.status === "Onboarding") {
    if (!formData.effectiveDate) missingFields.push("Effective Date");
    if (!formData.account) missingFields.push("Account");
    if (!formData.lob) missingFields.push("LOB");
    if (!formData.task) missingFields.push("Task");
    if (!formData.contact1) missingFields.push("Contact 1");
    if (!formData.contactNo1) missingFields.push("Contact No 1");
    if (!formData.msaDate) missingFields.push("MSA Date");

    if (missingFields.length > 0) {
      setError(`Fill up all required fields (*): ${missingFields.join(", ")}`);
      return false;
    }
  }

  if (formData.status === "Active") {
    if (!formData.effectiveDate) missingFields.push("Effective Date");
    if (!formData.account) missingFields.push("Account");
    if (!formData.lob) missingFields.push("LOB");
    if (!formData.task) missingFields.push("Task");
    if (!formData.contact1) missingFields.push("Contact 1");
    if (!formData.contactNo1) missingFields.push("Contact No 1");
    if (!formData.drfte) missingFields.push("DRFTE");
    if (!formData.phfte) missingFields.push("PHFTE");
    if (!formData.staffingModel) missingFields.push("Staffing Model");
    if (!formData.billingCycle) missingFields.push("Billing Cycle");
    if (!formData.dailyWorkHrs) missingFields.push("Daily Work Hours");
    if (!formData.holidayHrs) missingFields.push("Holiday Hours");
    if (!formData.regularRate) missingFields.push("Regular Rate");
    if (!formData.premiumRate) missingFields.push("Premium Rate");
    if (!formData.depositFee) missingFields.push("Deposit Fee");
    if (!formData.depositFeeWaived) missingFields.push("Deposit Fee Waived");
    if (!formData.setupFeeWaived) missingFields.push("Setup Fee Waived");
    if (!formData.setupFee) missingFields.push("Setup Fee");
    if (!formData.extraMonitorFeePerUnit) missingFields.push("Extra Monitor Fee Per Unit");
    if (!formData.extraMonitorQty) missingFields.push("Extra Monitor Quantity");
    if (!formData.phoneLineFeePerFTEPerMonth) missingFields.push("Phone Line Fee Per FTE Per Month");
    if (!formData.msaDate) missingFields.push("MSA Date");
    if (!formData.liveDate) missingFields.push("Live Date");

    if (missingFields.length > 0) {
      setError(`Fill up all required fields (*): ${missingFields.join(", ")}`);
      return false;
    }
  }

  if (formData.status === "Discontinued") {
    if (!formData.termDate) {
      setError('Fill up all required fields (*): Term Date');
      return false;
    }
  }

  setError(`Update Successful`);
  return true;
};

const handleSubmit = (e) => {
  e.preventDefault();
  if (!validateForm()) {
    return; // Stop submission if validation fails
  }
  console.log(formData)
  // return 
  axios.post(`${SERVER_URL}/addClient`, formData)
    .then(response => {
      toast.success(`Data for ${formData.accountCode} updated successfully.`);
      setTimeout(() => {
        navigate('/dashboard');
      }, 3000); // Match autoClose duration from ToastContainer
    })
    .catch(error => {
      toast.error('Error updating client information. Please try again.');
      setError('Error updating client information');
    });
};

  // Function to handle the cancel action
  const handleCancel = () => {
    navigate('/UpdateClient'); // Navigate to the previous page

  };

  const handleExit = () => {
    navigate('/Dashboard'); // Navigate to Dashboard

  };

  const reqD1 = () => {
    if (formData.status === "Active" || formData.status === "Onboarding" ) {
      return "*";
    } else {
      return "";
    }
  };

  const reqD2 = () => {
    if (formData.status === "Active") {
      return "*";
    } else {
      return "";
    }
  };

  const reqD3 = () => {
    if (formData.status === "Discontinued"  ) {
      return "*";
    } else {
      return "";
    }
  };


  return (
    <div className="sidebar-and-main-container">
      <Sidebar />

      <div className="addclient-info-container">
        <h2 className="form-title">Update Client Information</h2>
        <div className="save-update-cancel-button-container">
      {/* Toggle Edit Mode Button */}
      <button
        type="button"
        className={isEditable ? "cancel-button" : "update-button"}
        onClick={() => {
          if (isEditable) {
            handleCancel(); // Reset form if Cancel is clicked
          }
          setIsEditable(!isEditable);
        }}
      >
        {isEditable ? "Cancel" : "Update"}
      </button>

      {/* Save Button */}
      {isEditable && (
        <button type="submit" className="save-button" onClick={handleSubmit}>
          Save
        </button>)}

        {!isEditable && (
        <button type="submit" className="back-button" onClick={handleExit}>
          Back
        </button>
        )}

    </div>
        <div className="addClientform-spacer"></div>
        {error && <div className={getMessageClass()}>{error}</div>}
        <ToastContainer position="top-right" autoClose={3000} hideProgressBar />

        <form className="addClient-info-form" onSubmit={handleSubmit}>
          <div className="addClient-form-column">
            {/* Column 1 */}
            <div className="addClient-form-group">
            <label>Status<span style={{ color: 'red' }}>*</span></label>
            <select
            name="status"
            value={formData.status}
            onChange={handleInputChange}
            disabled={!isEditable}
    >
        {/* Render options conditionally based on clientData.STATUS */}
            <option value="Active">Active</option>
                {!(clientData.STATUS === "Active" || clientData.STATUS === "Discontinued" || clientData.STATUS === "Cancelled") && (
                    <>
                        <option value="Prospect Client">Prospect Client</option>
                        <option value="Onboarding">Onboarding</option>
                    </>
                )}
                  <option value="On Hold">On Hold</option>
                  <option value="Discontinued">Discontinued</option>
                  <option value="Cancelled">Cancelled</option>
                </select>
            </div>
            <div className="addClient-form-group">
              <label>Effective Date <span style={{ color: 'red' }}>*</span></label>
              <input type="date" name="effectiveDate" value={formData.effectiveDate} onChange={handleInputChange} disabled={!isEditable} />
            </div>
            <div className="addClient-form-group">
              <label>Account <span style={{ color: 'red' }}>*</span></label>
              <textarea name="account" rows="1" className="addClient-input" value={formData.account} onChange={handleInputChange} disabled></textarea>
            </div>
            <div className="addClient-form-group">
              <label>Contact Person 1 <span style={{ color: 'red' }}>*</span></label>
              <textarea name="contact1" rows="1" className="addClient-input" value={formData.contact1} onChange={handleInputChange} disabled={!isEditable}></textarea>
            </div>
            <div className="addClient-form-group">
              <label>Business Address</label>
              <textarea name="busAddress" rows="3" className="addclient-fixed-height-textarea" value={formData.busAddress} onChange={handleInputChange} disabled={!isEditable}></textarea>
            </div>
            <div className="addClientform-spacer"></div>
            <div className="addClient-form-group">
              <label>DR FTE <span style={{ color: 'red' }}>{reqD2()}</span></label>
              <input type="number" step="1" name="drfte" value={formData.drfte} onChange={handleInputChange} disabled={!isEditable} />
            </div>
            <div className="addClient-form-group">
              <label>Daily Working Hours <span style={{ color: 'red' }}>{reqD2()}</span></label>
              <input type="number" step="1" name="dailyWorkHrs" value={formData.dailyWorkHrs} onChange={handleInputChange} disabled={!isEditable} />
            </div>
            <div className="addClient-form-group">
              <label>Deposit Fee <span style={{ color: 'red' }}>{reqD2()}</span></label>
              <input type="number" step="0.01" name="depositFee" value={formData.depositFee} onChange={handleInputChange} disabled={!isEditable} />
            </div>
            <div className="addClient-form-group">
              <label>Phone Line Fee per FTE/Month <span style={{ color: 'red' }}>{reqD2()}</span></label>
              <input type="number" step="1" name="phoneLineFeePerFTEPerMonth" value={+formData.phoneLineFeePerFTEPerMonth} onChange={handleInputChange} disabled={!isEditable} />
            </div>
            <div className="addClient-form-group">
              <label>Notes</label>
              <textarea name="notes" rows="3" className="notes-textarea" value={formData.notes} onChange={handleInputChange}></textarea>
            </div>
          </div>

          <div className="addClient-form-column">
            {/* Column 2 */}
            <div className="addClient-form-group">
              <label>Account Code <span style={{ color: 'red' }}>*</span></label>
              <textarea name="accountCode" rows="1" className="addClient-input" value={formData.accountCode} onChange={handleInputChange} disabled></textarea>
            </div>
            <div className="addClient-form-group">
              <label>MSA Date <span style={{ color: 'red' }}>{reqD1()}</span></label>
              <input type="date" name="msaDate" value={formData.msaDate} onChange={handleInputChange} disabled={!isEditable} />
            </div>
            <div className="addClient-form-group">
              <label>LOB<span style={{ color: 'red' }}>*</span></label>
              <textarea name="lob" rows="1" className="addClient-input" value={formData.lob} onChange={handleInputChange} disabled={!isEditable}></textarea>
            </div>
            <div className="addClient-form-group">
              <label>Contact Person 2</label>
              <textarea name="contact2" rows="1" className="addClient-input" value={formData.contact2} onChange={handleInputChange} disabled={!isEditable}></textarea>
            </div>
            <div className="addClient-form-group">
              <label>State</label>
              <select name="state" value={formData.state} onChange={handleInputChange} disabled={!isEditable}>
                <option value="AL">Alabama</option>
                <option value="AK">Alaska</option>
                <option value="AZ">Arizona</option>
                <option value="AR">Arkansas</option>
                <option value="CA">California</option>
                <option value="CO">Colorado</option>
                <option value="CT">Connecticut</option>
                <option value="DE">Delaware</option>
                <option value="FL">Florida</option>
                <option value="GA">Georgia</option>
                <option value="HI">Hawaii</option>
                <option value="ID">Idaho</option>
                <option value="IL">Illinois</option>
                <option value="IN">Indiana</option>
                <option value="IA">Iowa</option>
                <option value="KS">Kansas</option>
                <option value="KY">Kentucky</option>
                <option value="LA">Louisiana</option>
                <option value="ME">Maine</option>
                <option value="MD">Maryland</option>
                <option value="MA">Massachusetts</option>
                <option value="MI">Michigan</option>
                <option value="MN">Minnesota</option>
                <option value="MS">Mississippi</option>
                <option value="MO">Missouri</option>
                <option value="MT">Montana</option>
                <option value="NE">Nebraska</option>
                <option value="NV">Nevada</option>
                <option value="NH">New Hampshire</option>
                <option value="NJ">New Jersey</option>
                <option value="NM">New Mexico</option>
                <option value="NY">New York</option>
                <option value="NC">North Carolina</option>
                <option value="ND">North Dakota</option>
                <option value="OH">Ohio</option>
                <option value="OK">Oklahoma</option>
                <option value="OR">Oregon</option>
                <option value="PA">Pennsylvania</option>
                <option value="RI">Rhode Island</option>
                <option value="SC">South Carolina</option>
                <option value="SD">South Dakota</option>
                <option value="TN">Tennessee</option>
                <option value="TX">Texas</option>
                <option value="UT">Utah</option>
                <option value="VT">Vermont</option>
                <option value="VA">Virginia</option>
                <option value="WA">Washington</option>
                <option value="WV">West Virginia</option>
                <option value="WI">Wisconsin</option>
                <option value="WY">Wyoming</option>

                {/* Add other states here */}
              </select>
            </div>
            <div className="addClientform-spacer"></div>
            <div className="addClient-form-group">
              <label>PH FTE <span style={{ color: 'red' }}>{reqD2()}</span></label>
              <input type="number" step="1" name="phfte" value={formData.phfte} onChange={handleInputChange} disabled={!isEditable}/>
            </div>
            <div className="addClient-form-group">
              <label>Holiday Hours <span style={{ color: 'red' }}>{reqD2()}</span></label>
              <input type="number" step="1" name="holidayHrs" value={formData.holidayHrs} onChange={handleInputChange} disabled={!isEditable}/>
            </div>
            <div className="addClient-form-group">
              <label>Deposit Fee Waived <span style={{ color: 'red' }}>{reqD2()}</span></label>
              <select name="depositFeeWaived" value={formData.depositFeeWaived} onChange={handleInputChange} disabled={!isEditable}>
                <option value="no">No</option>
                <option value="yes">Yes</option>
              </select>
            </div>
            <div className="addClient-form-group">
              <label>Extra Monitor Fee per Unit <span style={{ color: 'red' }}>{reqD2()}</span></label>
              <input type="number" step="0.01" name="extraMonitorFeePerUnit" value={formData.extraMonitorFeePerUnit} onChange={handleInputChange} disabled={!isEditable}/>
            </div>
          </div>

          <div className="addClient-form-column">
            {/* Column 3 */}
            <div className="addClient-form-group">
              <label>Site <span style={{ color: 'red' }}>*</span></label>
              <select name="site" value={formData.site} onChange={handleInputChange} disabled={!isEditable}>
                <option value="PH">PH</option>
                <option value="DR">DR</option>
                <option value="Blended">Blended</option>
              </select>
            </div>
            <div className="addClient-form-group">
            <label>Live Date <span style={{ color: 'red' }}>{reqD2()}</span></label>
              <input type="date" name="liveDate" value={formData.liveDate} onChange={handleInputChange} disabled={!isEditable} />
            </div>
            <div className="addClient-form-group">
              <label>Task <span style={{ color: 'red' }}>*</span></label>
              <textarea name="task" rows="3" className="fixed-height-textarea" value={formData.task} onChange={handleInputChange} disabled={!isEditable}></textarea>
            </div>
            <div className="addClient-form-group">
              <label>Contact Number 1</label>
              <input type="number" step="1" name="contactNo1" value={formData.contactNo1} onChange={handleInputChange} disabled={!isEditable}/>
            </div>
            <div className="addClient-form-group">
              <label>Salesperson <span style={{ color: 'red' }}>*</span></label>
              <select name="salesperson" value={formData.salesperson} onChange={handleInputChange} disabled={!isEditable}>
                <option value="In House">In House</option>
                <option value="Chaim Greenfeld">Chaim Greenfeld</option>
                <option value="Chaim Schnitzler">Chaim Schnitzler</option>
                <option value="Dave Dial">Dave Dial</option>
                <option value="Isaac Joseph">Isaac Joseph</option>
                <option value="Christopher Mojica">Christopher Mojica</option>
              </select>
            </div>
            <div className="addClientform-spacer"></div>
            <div className="addClient-form-group">
              <label>Staffing Model <span style={{ color: 'red' }}>{reqD2()}</span></label>
              <select name="staffingModel" value={formData.staffingModel} onChange={handleInputChange} disabled={!isEditable}>
                <option value="FTE-Based">FTE-Based</option>
                <option value="Project-Based">Project-Based</option>
              </select>
            </div>
            <div className="addClient-form-group">
              <label>Regular Rate <span style={{ color: 'red' }}>{reqD2()}</span></label>
              <input type="number" step="0.01" name="regularRate" value={formData.regularRate} onChange={handleInputChange} disabled={!isEditable} />
            </div>
            <div className="addClient-form-group">
              <label>Set-up Fee <span style={{ color: 'red' }}>{reqD2()}</span></label>
              <input type="number" step="0.01" name="setupFee" value={formData.setupFee} onChange={handleInputChange} disabled={!isEditable} />
            </div>
            <div className="addClient-form-group">
              <label>Extra Monitor Qty <span style={{ color: 'red' }}>{reqD2()}</span></label>
              <input type="number" step="1" name="extraMonitorQty" value={formData.extraMonitorQty} onChange={handleInputChange} disabled={!isEditable} />
            </div>
          </div>

          <div className="addClient-form-column">
            {/* Column 4 */}
            <div className="addClient-form-group"></div>
            <div className="addClient-form-group">
              {formData.status === "Discontinued" ? (
                <>
                <label>Termination Date <span style={{ color: 'red' }}>{reqD3()}</span></label>
                <input type="date" name="termDate" value={formData.termDate} onChange={handleInputChange} disabled={!isEditable}/>
                </>
              ) : ''}
            </div>
            <div className="addClient-form-group"></div>
            <div className="addClient-form-group">
              <label>Contact Number 2</label>
              <input type="number" step="1" name="contactNo2" value={formData.contactNo2} onChange={handleInputChange} disabled={!isEditable} />
            </div>
            <div className="addClient-form-group"></div>
            <div className="addClientform-spacer"></div>
            <div className="addClient-form-group">
              <label>Billing Cycle <span style={{ color: 'red' }}>{reqD2()}</span></label>
              <select name="billingCycle" value={formData.billingCycle} onChange={handleInputChange} disabled={!isEditable}>
                <option value="Monthly">Monthly</option>
                <option value="Quarterly">Quarterly</option>
                <option value="Weekly">Weekly</option>
              </select>
            </div>
            <div className="addClient-form-group">
              <label>Premium Rate <span style={{ color: 'red' }}>{reqD2()}</span></label>
              <input type="number" step="0.01" name="premiumRate" value={formData.premiumRate} onChange={handleInputChange} disabled={!isEditable}/>
            </div>
            <div className="addClient-form-group">
              <label>Set-up Fee Waived <span style={{ color: 'red' }}>{reqD2()}</span></label>
              <select name="setupFeeWaived" value={formData.setupFeeWaived} onChange={handleInputChange} disabled={!isEditable}>
              <option value="no">No</option>
              <option value="yes">Yes</option>
              </select>
            </div>
          </div>
          </form>
      </div>
    </div>
  );
};


export default UpdateClient;
