import React, { useEffect, useState } from 'react';

const Display = () => {
  const [clients, setClients] = useState([]);

  // Fetch and sort data from the backend
  useEffect(() => {
    fetch('http://localhost:5000/api/clients')
      .then(response => response.json())
      .then(data => {
        // Sort clients by ACCOUNT, LOB, and TASK
        const sortedData = data.sort((a, b) => {
          const accountComparison = a.ACCOUNT.localeCompare(b.ACCOUNT);
          if (accountComparison !== 0) return accountComparison;

          const lobComparison = a.LOB.localeCompare(b.LOB);
          if (lobComparison !== 0) return lobComparison;

          return a.TASK.localeCompare(b.TASK);
        });
        setClients(sortedData);  // Set sorted data to state
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  // Function to format EFFECTIVEDATE to short date format
  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <div className="App">
      <header className="App-header">
        <h1>Client Roster</h1>
        <table border="1">
          <thead>
            <tr>
              <th>Effective Date</th>
              <th>Account Code</th>
              <th>Account</th>
              <th>LOB</th>
              <th>Task</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {clients.map(client => (
              <tr key={client.ID}>
                <td>{formatDate(client.EFFECTIVEDATE)}</td> {/* Format Effective Date */}
                <td>{client.ACCOUNTCODE}</td>
                <td>{client.ACCOUNT}</td>
                <td>{client.LOB}</td>
                <td>{client.TASK}</td>
                <td>{client.STATUS}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </header>
    </div>
  );
};

export default Display;
