import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import UserService from "../service/UserService";
import './RegisterPage.css';

// Import images from assets
import cmxLogo from '../../assets/cmxlogo-removebg-preview.png';
import emailIcon from '../../assets/user_icon.png';
import passwordIcon from '../../assets/pass_icon.png';
import roleIcon from '../../assets/role_icon.png';
import showIcon from '../../assets/eye_icon.png';  
import hideIcon from '../../assets/eye_slash_icon.png';  

function RegisterPage() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [role, setRole] = useState('USER');  // Default to 'USER'
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const navigate = useNavigate();

  // Fetch current user details on load (to populate form for updates)
  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem('token');
      try {
        const userData = await UserService.getUserDetails(token); // Assuming getUserDetails() exists in UserService
        setName(userData.name);
        setEmail(userData.email);
        setRole(userData.user_role);
      } catch (error) {
        setError('Failed to load user data.');
      }
    };
    
    fetchUserData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    const token = localStorage.getItem('token');

    // Prepare user data
    const userData = {
      name,
      email,
      password,  // Password will be hashed server-side
      role,
    };

    try {
      if (password) {
        // Call API to update user with new password
        await UserService.updateUser(userData, token); // Assuming updateUser() exists in UserService
        setSuccess('Update successful! Redirecting to login...');
        setTimeout(() => navigate('/login'), 3000); // Redirect to login after 3 seconds
      } else {
        setError('Password cannot be empty');
      }
    } catch (error) {
      setError(error.message);
      setTimeout(() => setError(''), 5000); // Clear error after 5 seconds
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <div className="register-page-wrapper">
      <div className="register-auth-container">
        <div className="register-form-container">
          <div className="register-logo-container">
            <img src={cmxLogo} alt="Callmax Logo" />
          </div>
          <h1 className="register-title">Register / Update Profile</h1>
          {error && <p className="register-error-message">{error}</p>}
          {success && <p className="register-success-message">{success}</p>}
          <form onSubmit={handleSubmit}>
            <div className="register-form-group">
              <label>Name</label>
              <div className="register-input-wrapper">
                <input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </div>
            </div>

            <div className="register-form-group">
              <label>Email</label>
              <div className="register-input-wrapper">
                <img src={emailIcon} alt="email icon" className="register-input-icon" />
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
            </div>

            <div className="register-form-group">
              <label>New Password</label>
              <div className="register-input-wrapper">
                <img src={passwordIcon} alt="password icon" className="register-input-icon" />
                <input
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <button
                  type="button"
                  className="register-toggle-password-btn"
                  onClick={togglePasswordVisibility}
                >
                  <img
                    src={showPassword ? hideIcon : showIcon}
                    alt={showPassword ? "Hide password" : "Show password"}
                    className="register-eye-icon"
                  />
                </button>
              </div>
            </div>

            <div className="register-form-group">
              <label>Role</label>
              <div className="register-input-wrapper">
                <img src={roleIcon} alt="role icon" className="register-input-icon" />
                <select
                  value={role}
                  onChange={(e) => setRole(e.target.value)}
                  required
                >
                  <option value="USER">User</option>
                  <option value="ADMIN">Admin</option>
                </select>
              </div>
            </div>

            <button type="submit" className="register-btn" disabled={!name || !email || !password}>
              Update Profile
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default RegisterPage;
