import React from 'react';
import { Link } from 'react-router-dom';


const AccessDenied = () => {
  return (
    <div style={styles.container}>
      <h1 style={styles.title}>Access Denied</h1>
      <p style={styles.message}>You do not have permission to access this page.</p>
      <Link to="/Escalations" style={styles.link}>
        Go back to Escalations
      </Link>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    textAlign: 'center',
    backgroundColor: '#f9f9f9',
  },
  title: {
    fontSize: '2rem',
    fontWeight: 'bold',
    color: '#ff0000',
    marginBottom: '1rem',
  },
  message: {
    fontSize: '1.2rem',
    marginBottom: '1.5rem',
    color: '#555',
  },
  link: {
    fontSize: '1rem',
    color: '#007bff',
    textDecoration: 'none',
    border: '1px solid #007bff',
    padding: '0.5rem 1rem',
    borderRadius: '5px',
    backgroundColor: '#f0f8ff',
  },
};

export default AccessDenied;
