import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import axios from 'axios';
import Modal from 'react-modal';
import Sidebar from '../../common/Sidebar';
import { SERVER_URL } from '../../lib/constants';
import './AddClient.css';

const INITIAL_FORM_DATA = {
  effectiveDate: new Date().toISOString().split('T')[0],
  accountCode: '(Auto Generated)',
  qbAccount: '',
  account: '',
  lob: '',
  task: '',
  msaDate: null,
  liveDate: null,
  staffingModel: 'N/A',
  drfte: null,
  phfte: null,
  dailyWorkHrs: null,
  holidayHrs: null,
  regularRate: null,
  premiumRate: null,
  depositFee: null,
  depositFeeWaived: 'no',
  setupFee: null,
  setupFeeWaived: 'no',
  extraMonitorFeePerUnit: null,
  extraMonitorQty: null,
  phoneLineFeePerFTEPerMonth: null,
  billingCycle: 'Monthly',
  status: 'Prospect Client',
  busAddress: '',
  state: '',
  contact1: '',
  contactNo1: '',
  contact2: '',
  contactNo2: '',
  salesperson: 'In House',
  notes: '',
  termDate: null,
  site: null
};

const AddClient = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const clientData = location.state?.clientData || {};

  const [formData, setFormData] = useState({ ...INITIAL_FORM_DATA, ...clientData });
  const [maxId, setMaxId] = useState('001');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchMaxId = async () => {
      try {
        const response = await axios.get(`${SERVER_URL}/getMaxId`);
        const incrementedId = String(response.data.maxId + 1).padStart(3, '0');
        setMaxId(incrementedId);
      } catch (error) {
        console.error('Error fetching max ID:', error);
      }
    };
    fetchMaxId();
  }, []);

  useEffect(() => {
    const generateAccountCode = () => {
      const { account, task } = formData;
      if (account && task && maxId) {
        const firstLetter = account.charAt(0).toUpperCase();
        const firstTwoLetters = task.substring(0, 2).toUpperCase();
        const date = new Date();
        const year = String(date.getFullYear()).substring(2);
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const generatedCode = `${firstLetter}${firstTwoLetters}-${year}${month}-${maxId}`;
        setFormData(prevData => ({ ...prevData, accountCode: generatedCode }));
      }
    };
    generateAccountCode();
  }, [formData.account, formData.task, maxId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
      ...(name === 'account' && { qbAccount: value })
    }));
  };

  const validateForm = () => {
    const missingFields = [];
  
    if (formData.status === "Prospect Client") {
      if (!formData.effectiveDate) missingFields.push("Effective Date");
      if (!formData.account) missingFields.push("Account");
      if (!formData.lob) missingFields.push("LOB");
      if (!formData.task) missingFields.push("Task");
      if (!formData.contact1) missingFields.push("Contact 1");
      if (!formData.contactNo1) missingFields.push("Contact No 1");
  
      if (missingFields.length > 0) {
        setError(`Fill up all required fields (*): ${missingFields.join(", ")}`);
        return false;
      }
    }
  
    if (formData.status === "Onboarding") {
      if (!formData.effectiveDate) missingFields.push("Effective Date");
      if (!formData.account) missingFields.push("Account");
      if (!formData.site) missingFields.push("Site");
      if (!formData.lob) missingFields.push("LOB");
      if (!formData.task) missingFields.push("Task");
      if (!formData.contact1) missingFields.push("Contact 1");
      if (!formData.contactNo1) missingFields.push("Contact No 1");
      if (!formData.msaDate) missingFields.push("MSA Date");
  
      if (missingFields.length > 0) {
        setError(`Fill up all required fields (*): ${missingFields.join(", ")}`);
        return false;
      }
    }
  
    if (formData.status === "Active") {
      if (!formData.effectiveDate) missingFields.push("Effective Date");
      if (!formData.account) missingFields.push("Account");
      if (!formData.lob) missingFields.push("LOB");
      if (!formData.task) missingFields.push("Task");
      if (!formData.contact1) missingFields.push("Contact 1");
      if (!formData.contactNo1) missingFields.push("Contact No 1");
      if (!formData.drfte) missingFields.push("DRFTE");
      if (!formData.phfte) missingFields.push("PHFTE");
      if (!formData.staffingModel) missingFields.push("Staffing Model");
      if (!formData.billingCycle) missingFields.push("Billing Cycle");
      if (!formData.dailyWorkHrs) missingFields.push("Daily Work Hours");
      if (!formData.holidayHrs) missingFields.push("Holiday Hours");
      if (!formData.regularRate) missingFields.push("Regular Rate");
      if (!formData.premiumRate) missingFields.push("Premium Rate");
      if (!formData.depositFee) missingFields.push("Deposit Fee");
      if (!formData.depositFeeWaived) missingFields.push("Deposit Fee Waived");
      if (!formData.setupFeeWaived) missingFields.push("Setup Fee Waived");
      if (!formData.setupFee) missingFields.push("Setup Fee");
      if (!formData.extraMonitorFeePerUnit) missingFields.push("Extra Monitor Fee Per Unit");
      if (!formData.extraMonitorQty) missingFields.push("Extra Monitor Quantity");
      if (!formData.phoneLineFeePerFTEPerMonth) missingFields.push("Phone Line Fee Per FTE/Month");
      if (!formData.msaDate) missingFields.push("MSA Date");
      if (!formData.liveDate) missingFields.push("Live Date");
  
      if (missingFields.length > 0) {
        setError(`Fill up all required fields (*): ${missingFields.join(", ")}`);
        return false;
      }
    }
  
    if (formData.status === "Discontinued") {
      if (!formData.termDate) {
        setError('Fill up all required fields (*): Termination Date');
        return false;
      }
    }
    
    setError(`${formData.account} Added Successful`);
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    try {
      await axios.post(`${SERVER_URL}/addClient`, formData);
      setModalMessage(`Client added successfully with account id: ${formData.accountCode}`);
      setIsSuccessful(true);
      setModalIsOpen(true);
      setFormData(INITIAL_FORM_DATA);
    } catch (error) {
      console.error('Error adding client:', error);
      setModalMessage('Failed to add client. Please try again.');
      setIsSuccessful(false);
      setModalIsOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    if (isSuccessful) {
      navigate('/dashboard');
    }
  };

  const reqD1 = () => {
    if (formData.status === "Active" || formData.status === "Onboarding" ) {
      return "*";
    } else {
      return "";
    }
  };

  const reqD2 = () => {
    if (formData.status === "Active") {
      return "*";
    } else {
      return "";
    }
  };

  const getMessageClass = () => {
    return error.toLowerCase().includes("success") ? "success-message" : "error-message";
  };

  return (
    <div className="sidebar-and-main-container">
      <Sidebar />
      <div className="addclient-info-container">
        <h2 className="form-title">Add Client Information</h2>
        <div className="save-update-cancel-button-container">
          <button type="submit" className="save-button" onClick={handleSubmit} disabled={loading}>
            {loading ? 'Saving...' : 'Save'}
          </button>
          <button type="button" className="cancel-button" onClick={handleCancel}>Cancel</button>
        </div>
        
        <div className="addClientform-spacer"></div>
        {error && <div className={getMessageClass()}>{error}</div>}

        <form className="addClient-info-form" >
        <div className="addClient-form-column">
            {/* Column 1 */}
            <div className="addClient-form-group">
              <label>Status <span style={{ color: 'red' }}>*</span></label>
                <select name="status" value={formData.status} onChange={handleInputChange}>
                  <option value="Prospect Client">Prospect Client</option>
                  <option value="Onboarding">Onboarding</option>
                  <option value="Active">Active</option>
              </select>
            </div>
            <div className="addClient-form-group">
              <label>Effective Date <span style={{ color: 'red' }}>*</span></label>
              <input type="date" name="effectiveDate" value={formData.effectiveDate} onChange={handleInputChange}
              />
            </div>
            <div className="addClient-form-group">
              <label>Account <span style={{ color: 'red' }}>*</span></label>
              <textarea name="account" rows="1" className="addClient-input" value={formData.account} onChange={handleInputChange}></textarea>
            </div>
            <div className="addClient-form-group">
              <label>Contact Person 1 <span style={{ color: 'red' }}>*</span></label>
              <textarea name="contact1" rows="1" className="addClient-input" value={formData.contact1} onChange={handleInputChange}></textarea>
            </div>
            <div className="addClient-form-group">
              <label>Business Address</label>
              <textarea name="busAddress" rows="3" className="addclient-fixed-height-textarea" value={formData.busAddress} onChange={handleInputChange}></textarea>
            </div>
            <div className="addClientform-spacer"></div>
            <div className="addClient-form-group">
              <label>DR FTE <span style={{ color: 'red' }}>{reqD2()}</span></label>
              <input type="number" step="1" name="drfte" value={formData.drfte} onChange={handleInputChange} />
            </div>
            <div className="addClient-form-group">
              <label>Daily Working Hours<span style={{ color: 'red' }}>{reqD2()}</span></label>
              <input type="number" step="1" name="dailyWorkHrs" value={formData.dailyWorkHrs} onChange={handleInputChange} />
            </div>
            <div className="addClient-form-group">
              <label>Deposit Fee <span style={{ color: 'red' }}>{reqD2()}</span></label>
              <input type="number" step="0.01" name="depositFee" value={formData.depositFee} onChange={handleInputChange} />
            </div>
            <div className="addClient-form-group">
              <label>Phone Line Fee per FTE/Month <span style={{ color: 'red' }}>{reqD2()}</span></label>
              <input type="number" step="1" name="phoneLineFeePerFTEPerMonth" value={formData.phoneLineFeePerFTEPerMonth} onChange={handleInputChange} />
            </div>
            <div className="addClient-form-group">
              <label>Notes</label>
              <textarea name="notes" rows="3" className="notes-textarea" value={formData.notes} onChange={handleInputChange}></textarea>
            </div>
          </div>

          <div className="addClient-form-column">
            {/* Column 2 */}
            <div className="addClient-form-group">
              <label>Account Code</label>
              <textarea name="accountCode" rows="1" className="addClient-input" value={formData.accountCode} onChange={handleInputChange} readOnly></textarea>
            </div>
            <div className="addClient-form-group">
              <label>MSA Date <span style={{ color: 'red' }}>{reqD1()}</span></label>
              <input type="date" name="msaDate" value={formData.msaDate} onChange={handleInputChange} />
            </div>
            <div className="addClient-form-group">
              <label>LOB <span style={{ color: 'red' }}>*</span></label>
              <textarea name="lob" rows="1" className="addClient-input" value={formData.lob} onChange={handleInputChange}></textarea>
            </div>
            <div className="addClient-form-group">
              <label>Contact Person 2</label>
              <textarea name="contact2" rows="1" className="addClient-input" value={formData.contact2} onChange={handleInputChange}></textarea>
            </div>
            <div className="addClient-form-group">
              <label>State</label>
              <select name="state" value={formData.state} onChange={handleInputChange}>
                <option value="" disabled selected>(Select)</option>
                <option value="AL">Alabama</option>
                <option value="AK">Alaska</option>
                <option value="AZ">Arizona</option>
                <option value="AR">Arkansas</option>
                <option value="CA">California</option>
                <option value="CO">Colorado</option>
                <option value="CT">Connecticut</option>
                <option value="DE">Delaware</option>
                <option value="FL">Florida</option>
                <option value="GA">Georgia</option>
                <option value="HI">Hawaii</option>
                <option value="ID">Idaho</option>
                <option value="IL">Illinois</option>
                <option value="IN">Indiana</option>
                <option value="IA">Iowa</option>
                <option value="KS">Kansas</option>
                <option value="KY">Kentucky</option>
                <option value="LA">Louisiana</option>
                <option value="ME">Maine</option>
                <option value="MD">Maryland</option>
                <option value="MA">Massachusetts</option>
                <option value="MI">Michigan</option>
                <option value="MN">Minnesota</option>
                <option value="MS">Mississippi</option>
                <option value="MO">Missouri</option>
                <option value="MT">Montana</option>
                <option value="NE">Nebraska</option>
                <option value="NV">Nevada</option>
                <option value="NH">New Hampshire</option>
                <option value="NJ">New Jersey</option>
                <option value="NM">New Mexico</option>
                <option value="NY">New York</option>
                <option value="NC">North Carolina</option>
                <option value="ND">North Dakota</option>
                <option value="OH">Ohio</option>
                <option value="OK">Oklahoma</option>
                <option value="OR">Oregon</option>
                <option value="PA">Pennsylvania</option>
                <option value="RI">Rhode Island</option>
                <option value="SC">South Carolina</option>
                <option value="SD">South Dakota</option>
                <option value="TN">Tennessee</option>
                <option value="TX">Texas</option>
                <option value="UT">Utah</option>
                <option value="VT">Vermont</option>
                <option value="VA">Virginia</option>
                <option value="WA">Washington</option>
                <option value="WV">West Virginia</option>
                <option value="WI">Wisconsin</option>
                <option value="WY">Wyoming</option>
                {/* Add other states here */}
              </select>
            </div>
            <div className="addClientform-spacer"></div>
            <div className="addClient-form-group">
              <label>PH FTE <span style={{ color: 'red' }}>{reqD2()}</span></label>
              <input type="number" step="1" name="phfte" value={formData.phfte} onChange={handleInputChange} />
            </div>
            <div className="addClient-form-group">
              <label>Holiday Hours <span style={{ color: 'red' }}>{reqD2()}</span></label>
              <input type="number" step="1" name="holidayHrs" value={formData.holidayHrs} onChange={handleInputChange} />
            </div>
            <div className="addClient-form-group">
              <label>Deposit Fee Waived <span style={{ color: 'red' }}>{reqD2()}</span></label>
              <select name="depositFeeWaived" value={formData.depositFeeWaived} onChange={handleInputChange}>
                <option value="no">No</option>
                <option value="yes">Yes</option>
              </select>
            </div>
            <div className="addClient-form-group">
              <label>Extra Monitor Fee per Unit <span style={{ color: 'red' }}>{reqD2()}</span></label>
              <input type="number" step="0.01" name="extraMonitorFeePerUnit" value={formData.extraMonitorFeePerUnit} onChange={handleInputChange}/>
            </div>
          </div>

          <div className="addClient-form-column">
            {/* Column 3 */}
            <div className="addClient-form-group">
              <label>Site <span style={{ color: 'red' }}>{reqD2()}</span></label>
              <select name="site" value={formData.site} onChange={handleInputChange}>
              <option value="" disabled selected>(Select)</option>
                <option value="PH">PH</option>
                <option value="DR">DR</option>
                <option value="Blended">Blended</option>
              </select>
            </div>
            <div className="addClient-form-group">
            <label>Live Date <span style={{ color: 'red' }}>{reqD2()}</span></label>
              <input type="date" name="liveDate" value={formData.liveDate} onChange={handleInputChange} />
            </div>
            <div className="addClient-form-group">
              <label>Task <span style={{ color: 'red' }}>*</span></label>
              <textarea name="task" rows="3" className="fixed-height-textarea" value={formData.task} onChange={handleInputChange}></textarea>
            </div>
            <div className="addClient-form-group">
              <label>Contact Number 1 <span style={{ color: 'red' }}>*</span></label>
              <input type="number" step="1" name="contactNo1" value={formData.contactNo1} onChange={handleInputChange} />
            </div>
            <div className="addClient-form-group">
              <label>Salesperson <span style={{ color: 'red' }}>*</span></label>
              <select name="salesperson" value={formData.salesperson} onChange={handleInputChange}>
                <option value="In House">In House</option>
                <option value="Chaim Greenfeld">Chaim Greenfeld</option>
                <option value="Chaim Schnitzler">Chaim Schnitzler</option>
                <option value="Dave Dial">Dave Dial</option>
                <option value="Isaac Joseph">Isaac Joseph</option>
                <option value="Christopher Mojica">Christopher Mojica</option>
              </select>
            </div>
            <div className="addClientform-spacer"></div>
            <div className="addClient-form-group">
              <label>Staffing Model <span style={{ color: 'red' }}>{reqD2()}</span></label>
              <select name="staffingModel" value={formData.staffingModel} onChange={handleInputChange}>
                <option value="FTE-Based">FTE-Based</option>
                <option value="Project-Based">Project-Based</option>
              </select>
            </div>
            <div className="addClient-form-group">
              <label>Regular Rate <span style={{ color: 'red' }}>{reqD2()}</span></label>
              <input type="number" step="0.01" name="regularRate" value={formData.regularRate} onChange={handleInputChange} />
            </div>
            <div className="addClient-form-group">
              <label>Set-up Fee <span style={{ color: 'red' }}>{reqD2()}</span></label>
              <input type="number" step="0.01" name="setupFee" value={formData.setupFee} onChange={handleInputChange} />
            </div>
            <div className="addClient-form-group">
              <label>Extra Monitor Qty <span style={{ color: 'red' }}>{reqD2()}</span></label>
              <input type="number" step="1" name="extraMonitorQty" value={formData.extraMonitorQty} onChange={handleInputChange} />
            </div>
          </div>

          <div className="addClient-form-column">
            {/* Column 4 */}
            <div className="addClient-form-group"></div>
            <div className="addClient-form-group"></div>
            <div className="addClient-form-group"></div>
            <div className="addClient-form-group">
              <label>Contact Number 2</label>
              <input type="number" step="1" name="contactNo2" value={formData.contactNo2} onChange={handleInputChange} />
            </div>
            <div className="addClient-form-group"></div>
            <div className="addClientform-spacer"></div>
            <div className="addClient-form-group">
              <label>Billing Cycle <span style={{ color: 'red' }}>{reqD2()}</span></label>
              <select name="billingCycle" value={formData.billingCycle} onChange={handleInputChange}>
                <option value="Monthly">Monthly</option>
                <option value="Quarterly">Quarterly</option>
                <option value="Weekly">Weekly</option>
              </select>
            </div>
            <div className="addClient-form-group">
              <label>Premium Rate <span style={{ color: 'red' }}>{reqD2()}</span></label>
              <input type="number" step="0.01" name="premiumRate" value={formData.premiumRate} onChange={handleInputChange} />
            </div>
            <div className="addClient-form-group">
              <label>Set-up Fee Waived <span style={{ color: 'red' }}>{reqD2()}</span></label>
              <select name="setupFeeWaived" value={formData.setupFeeWaived} onChange={handleInputChange}>
              <option value="no">No</option>
              <option value="yes">Yes</option>
              </select>
            </div>
          </div>
          </form>

      {/* Modal Implementation */}
      <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Message Modal"
          className="modal"
          overlayClassName="overlay"
        >
          <h2>{modalMessage}</h2>
          <div className="newcancel-btn">
          <button
            className="mdlcreate-button"
            onClick={() => {
                setModalIsOpen(false);
                window.location.reload(); // Reload the page when "Create New" is clicked
            }}
        >
              Create New
          </button>
            <button className="mdlclose-button" onClick={closeModal}>Close</button>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default AddClient;