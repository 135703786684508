// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../node_modules/@fontsource/metropolis/index.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Import the Metropolis font */

* {
  font-family: 'Metropolis', sans-serif; /* Apply Metropolis globally */
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
`, "",{"version":3,"sources":["webpack://./src/styles.css"],"names":[],"mappings":"AAAkC,+BAA+B;;AAEjE;EACE,qCAAqC,EAAE,8BAA8B;EACrE,SAAS;EACT,UAAU;EACV,sBAAsB;AACxB","sourcesContent":["@import \"@fontsource/metropolis\"; /* Import the Metropolis font */\r\n\r\n* {\r\n  font-family: 'Metropolis', sans-serif; /* Apply Metropolis globally */\r\n  margin: 0;\r\n  padding: 0;\r\n  box-sizing: border-box;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
