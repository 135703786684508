import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './ForgotPassword.css';
import cmxLogo from '../../assets/cmxlogo-removebg-preview.png';
import forgotPW from '../../assets/forgotPasswordIcon.png';
import { SERVER_URL } from '../lib/constants';

function ForgotPassword() {
  const navigate = useNavigate();
  const location = useLocation();
  const [emailAddress, setEmailAddress] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [allUsers, setAllUsers] = useState([]);

  useEffect(() => {
    if (!location.state || !location.state.fromLogin) {
      navigate('/'); // Redirect to the home page or login page
    }
  }, [location.state, navigate]);

  // Fetch all users on component mount
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await fetch(`${SERVER_URL}/api/appUsers`);
        if (!response.ok) {
          console.error('Failed to fetch users.');
          setMessage('Error fetching user data. Please try again later.');
          return;
        }
        const users = await response.json();
        setAllUsers(users);
      } catch (error) {
        console.error('Error fetching users:', error);
        setMessage('An unexpected error occurred. Please try again.');
      }
    };

    fetchUsers();
  }, []);

  // Function to check if a user exists
  const checkUserExists = (email) => {
    return allUsers.some((user) => user.USERID?.toLowerCase() === email.toLowerCase().trim());
  };

  const generateOTP = () => Math.floor(100000 + Math.random() * 900000); // 6-digit numeric OTP

  const handleResetPassword = async () => {
    if (!emailAddress) {
      setMessage('Email address is required.');
      return;
    }

    // Check if the user exists
    const userExists = checkUserExists(emailAddress);
    if (!userExists) {
      setMessage('No user found with this email address.');
      return;
    }

    setLoading(true); // Disable the button and change its label

    const formatDateForMySQL = (date) => {
      return date.toISOString().slice(0, 19).replace('T', ' ');
    };

    // Generate OTP and timestamps
    const otp = generateOTP();
    const requestedDateTime = new Date(); // Current timestamp
    const expiryDateTime = new Date(requestedDateTime.getTime() + 5 * 60000); // 5 minutes from now

    const formattedRequestedDateTime = formatDateForMySQL(requestedDateTime);
    const formattedExpiryDateTime = formatDateForMySQL(expiryDateTime);

    try {
      // Send the POST request
      const response = await fetch(`${SERVER_URL}/pwResetOTP`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          emailAddress,
          otp,
          requestedDateTime: formattedRequestedDateTime,
          expiryDateTime: formattedExpiryDateTime,
        }),
      });

      if (!response.ok) {
        const errorMessage = await response.text();
        console.error('Error response from server:', errorMessage);
        setMessage('Failed to reset password.');
        setLoading(false); // Re-enable the button
        return;
      }

      // Navigate to OTP-Secure page with data
      navigate('/OTP-SECURE', { state: { emailAddress, otp, requestedDateTime: formattedRequestedDateTime, expiryDateTime: formattedExpiryDateTime } });
    } catch (error) {
      console.error('Network or unexpected error:', error);
      setMessage('An unexpected error occurred. Please try again.');
    } finally {
      setLoading(false); // Re-enable the button
    }
  };

  return (
    <div className="resetPass-page-wrapper">
      <div className="resetPass-auth-container">
        <div className="resetPass-form-container">
          <div className="resetPass-logo-container">
            <img src={cmxLogo} alt="CMX Logo" />
          </div>

          <h1
            className="resetPass-title"
            style={{
              color: 'black',
              fontSize: '24px',
              fontWeight: '800',
              marginLeft: '20px',
              marginBottom: '30px',
            }}
          >
            Forgot Password
          </h1>

          <div className="resetPass-form-group">
            <label style={{ fontWeight: '800' }}>Email Address:</label>
            <div className="resetPass-input-wrapper">
              <input
                type="email"
                value={emailAddress}
                onChange={(e) => setEmailAddress(e.target.value)}
                required
              />
            </div>
          </div>

          <button
            className="resetPass-btn"
            onClick={handleResetPassword}
            disabled={loading}
          >
            {loading ? 'Sending Request...' : 'Reset Password'}
          </button>
          {message && <p className="resetPass-message">{message}</p>}
        </div>

        <div className="resetPass-task-tracker-icon-container">
          <img
            src={forgotPW}
            alt="forgotPW"
            style={{ width: '400px', height: 'auto', marginRight: '40px' }}
          />
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
