// src/components/common/Sidebar.jsx
import React, { useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import UserService from '../service/UserService'; // Import UserService for role checking

// Import all the icons here
import dashboardIcon from '../../assets/dashboard_icon.png'; // Dashboard icon
import cmxLogo from '../../assets/cmxlogo-removebg-preview.png'; // Correct path for logo
import routesIcon from '../../assets/routes_icon.png'; // Routes icon
import portalIcon from '../../assets/portal_icon.png'; // Portal icon
import profileIcon from '../../assets/profile_icon.png'; // Profile icon

const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userRole = UserService.getRole(); 

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <nav className="sidebar">
      <div className="sidebar-logo">
        <img src={cmxLogo} alt="Callmax Logo" style={{ marginLeft: '20px' }} />
      </div>
      <ul className="nav-links">

      <li 
          className={`nav-item ${location.pathname === '/Escalations' ? 'active' : ''}`} // Check if the current path is '/routes'
          onClick={() => handleNavigation('/Escalations')}
        >
          <img
            src={routesIcon}
            alt="Routes Icon"
            style={{ width: '20px', height: '20px', marginRight: '10px' }}
          />
          Escalations
      </li>

      {userRole !== 'user' && (
      <li 
          className={`nav-item ${location.pathname === '/dashboard' ? 'active' : ''}`} // Check if the current path is '/dashboard'
          onClick={() => handleNavigation('/dashboard')}
        >
          <img
            src={dashboardIcon}
            alt="Dashboard Icon"
            style={{ width: '20px', height: '20px', marginRight: '10px' }}
          />
          Client Records
        </li>
      )}
        {/*<li 
          className={`nav-item ${location.pathname === '/addClient' ? 'active' : ''}`} // Check if the current path is '/routes'
          onClick={() => handleNavigation('/addClient')}
        >
          <img
            src={routesIcon}
            alt="Routes Icon"
            style={{ width: '20px', height: '20px', marginRight: '10px' }}
          />
          Add Client Info
        </li>*/}

         {/*<li className="nav-item" onClick={() => handleNavigation('/AddEscalationInfo')}>
          <img
            src={routesIcon}
            alt="Routes Icon"
            style={{ width: '20px', height: '20px', marginRight: '10px' }}
          />
          Add New Escalation
        </li> */}
        {/* <li className="nav-item" onClick={() => handleNavigation('/profile')}>
          <img
            src={profileIcon}
            alt="Profile Icon"
            style={{ width: '20px', height: '20px', marginRight: '10px' }}
          />
          Profile
        </li> */}
      </ul>

      {/* Footer Section */}
      <div className="sidebar-footer" style={{ padding: '20px', fontSize: '10px', color: '#000000', marginTop: 'auto' , textAlign: 'center' }}>
        <p style={{ margin: '0', fontWeight: 'bold' }}>Version 3.6.5</p>
        <p style={{ margin: '5px 0' }}>CMX Client Management Portal</p>
        <p style={{ margin: '5px 0' }}>Designed and Developed for CMX Client Management Team</p>
        <p style={{ margin: '5px 0' }}>By: SOFTDEV-DREAM</p>
        <p style={{ margin: '5px 0' }}>Callmax Solutions</p>
        <p style={{ margin: '5px 0'}}>2024</p>
        <p style={{ margin: '5px 0'}}>All Rights Reserved</p>
      </div>

    </nav>
  );
};

export default Sidebar;
