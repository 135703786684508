import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import './OTP.css';
import cmxLogo from '../../assets/cmxlogo-removebg-preview.png';
import otpVector from '../../assets/otp.png';

function OTP() {
  const location = useLocation();
  const navigate = useNavigate();

  // Extract state data from ForgotPassword
  const { emailAddress, otp: initialOtp } = location.state || {};

  const [enteredOtp, setEnteredOtp] = useState('');
  const [otp, setOtp] = useState(initialOtp || '');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Redirect to ForgotPassword if state is missing
    if (!location.state || !emailAddress || !initialOtp) {
      navigate('/');
    }
  }, [location.state, emailAddress, initialOtp, navigate]);

  // Function to handle OTP validation
  const handleOtpValidation = () => {
    setLoading(true);

    if (enteredOtp === String(otp)) {
      setMessage('OTP verified successfully.');
      
      // Store a dummy token in localStorage for testing
      const dummyToken = `token_${new Date().getTime()}`;
      localStorage.setItem('token', dummyToken);

      // Navigate to the New Password page and pass emailAddress
      navigate('/CREATE-NEWPASS', { state: { email: emailAddress } });
    } else {
      setMessage('Invalid OTP. Please try again.');
    }

    setLoading(false);
  };

  const handleOtpChange = (e) => {
    setEnteredOtp(e.target.value);
    setMessage('');
  };

  return (
    <div className="NewPass-page-wrapper">
      <div className="NewPass-auth-container">
        <div className="NewPass-form-container">
          <div className="NewPass-logo-container">
            <img src={cmxLogo} alt="CMX Logo" />
          </div>

          <h1 className="NewPass-title" style={{ color: 'black', fontSize: '24px', fontWeight: '800', marginBottom: '30px' }}>
            OTP Verification
          </h1>
          <p style={{ alignItems: 'center', textAlign: 'center', fontSize: '18px', fontWeight: '800', width: '100%', marginBottom: '20px' }}>
            An OTP was sent to your email.
          </p>

          <div className="NewPass-form-group">
            <label style={{ fontWeight: '800' }}>Enter OTP:</label>
            <div className="NewPass-input-wrapper">
              <input
                type="text"
                value={enteredOtp}
                onChange={handleOtpChange}
                required
              />
            </div>
          </div>

          <button 
            className="NewPass-btn" 
            onClick={handleOtpValidation}
            disabled={loading}
          >
            {loading ? "Validating..." : "Continue -->"}
          </button>

          {message && (
            <p style={{ color: message.includes("successfully") ? "green" : "red", marginTop: '10px' }}>
              {message}
            </p>
          )}
        </div>

      </div>
    </div>
  );
}

export default OTP;
