import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import * as XLSX from 'xlsx';
import './Escalations.css';
import Sidebar from '../../common/Sidebar.jsx'; 
import { IoMdRefresh } from "react-icons/io";
import axios from 'axios';
import UserService from '../../service/UserService.js';

import profilePlaceholderIcon from '../../../assets/profile_placeholder.png';
import logoutIcon from '../../../assets/logout_icon.png';
import addIcon from '../../../assets/add_icon.png';
import searchIcon from '../../../assets/search_symbol.png';
import downloadIcon from '../../../assets/download_icon.png';
import reloadIcon from '../../../assets/reload_icon.png'; 
import { SERVER_URL } from '../../lib/constants.js';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { PieChart, Pie, Cell, Tooltip, Legend } from 'recharts';

const Escalations = () => {
  const navigate = useNavigate(); 
  const tableRef = useRef(null);
  const [userid, setUserId] = useState('');
  const [isLogoutModalVisible, setIsLogoutModalVisible] = useState(false);
  const [rows, setRows] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [filteredRows, setFilteredRows] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  
  // Define your dashboard-related states here
  const [totalCount, setTotalCount] = useState(0);
  const [openCount, setOpenCount] = useState(0);
  const [closedCount, setClosedCount] = useState(0);
  const [resolvedCount, setResolvedCount] = useState(0);
  const [pendingCount, setPendingCount] = useState(0);
  const [unresolvedCount, setUnresolvedCount] = useState(0);
  const [assyCount, setAssyCount] = useState(0);
  const [nonAssyCount, setNonAssyCount] = useState(0);
  const [billingCount, setBillingCount] = useState(0);
  const [executiveCount, setExecutiveCount] = useState(0);
  const [reductionCount, setReductionCount] = useState(0);
  const [performanceCount, setPerformanceCount] = useState(0);
  const [leadershipCount, setLeadershipCount] = useState(0);
  const [outputCount, setOutputCount] = useState(0);
  const [termCount, setTermCount] = useState(0);
  const [issuesCount, setIssuesCount] = useState(0);
  const [othersCount, setOthersCount] = useState(0);
  const [critLowCount, setCritLowCount] = useState(0);
  const [critMidCount, setCritMidCount] = useState(0);
  const [critHiCount, setCritHiCount] = useState(0);
  const [critCritCount, setCritCritCount] = useState(0);


  const [escalationData, setEscalationData] = useState([
    { name: 'Billing Issues', value: billingCount },
    { name: 'Executive Escalation', value: executiveCount },
    { name: 'Headcount Reduction', value: reductionCount },
    { name: 'Performance Issue', value: performanceCount },
    { name: 'Leadership Concerns', value: leadershipCount },
    { name: 'Task / Output Feedback', value: outputCount },
    { name: 'Termination of Service', value: termCount },
    { name: 'Unresolved Issues', value: issuesCount },
    { name: 'Others', value: othersCount },
  ]);

  const [criticalityData, setCriticalityData] = useState([
    { name: 'Low', value: critLowCount },
    { name: 'Medium', value: critMidCount },
    { name: 'High', value: critHiCount },
    { name: 'Critical', value: critCritCount },
  ]);

  const escalCount = escalationData.reduce((acc, item) => acc + item.value, 0);

  const colors = ['#1F618D', '#5D6D7E', '#E74C3C', '#FF0000', '#2874A6', '#2E86C1', '#34495E', '#2C3E50', '#1A5276'];


  useEffect(() => {
    const storedUserId = localStorage.getItem('userid');
    setUserId(storedUserId);
  }, []);

  useEffect(() => {
    fetch(`${SERVER_URL}/api/escalations`,{
      method:"GET",
      headers:{
        userid:localStorage.getItem("userid"),
        role:localStorage.getItem("role")
      }
    })
      .then(response => response.json())
      .then(data => {
        setRows(data);
        setFilteredRows(data);

        const openStatusCount = data.filter(item => item.STATUS === 'Open').length;
        const closedStatusCount = data.filter(item => item.STATUS === 'Closed').length;
        const resResoCount = data.filter(item => item.RESOLUTIONSTATUS === 'Resolved').length;
        const pendResoCount = data.filter(item => item.RESOLUTIONSTATUS === '').length;
        const unresResoCount = data.filter(item => item.RESOLUTIONSTATUS === 'Unresolved').length;
        const assyTypeCount = data.filter(item => item.CLIENTCATEGORY === 'Assembly').length;
        const nonAssyTypeCount = data.filter(item => item.CLIENTCATEGORY === 'Non Assembly').length;
        const billingEscalCount = data.filter(item => item.ESCALATIONTYPE === 'Billing Issue').length;
        const executiveEscalCount = data.filter(item => item.ESCALATIONTYPE === 'Executive Escalation').length;
        const reductionEscalCount = data.filter(item => item.ESCALATIONTYPE === 'Headcount Reduction').length;
        const performanceEscalCount = data.filter(item => item.ESCALATIONTYPE === 'Performance Issue').length;
        const leadershipEscalCount = data.filter(item => item.ESCALATIONTYPE === 'Leadership Concerns').length;
        const outputEscalCount = data.filter(item => item.ESCALATIONTYPE === 'Task / Output Feedback').length;
        const termEscalCount = data.filter(item => item.ESCALATIONTYPE === 'Termination of Service').length;
        const issuesEscalCount = data.filter(item => item.ESCALATIONTYPE === 'Unresolved Issues').length;
        const othersEscalCount = data.filter(item => item.ESCALATIONTYPE === 'Others').length;
        const lowCritCount = data.filter(item => item.CRITICALITY === 'Low').length;
        const midCritCount = data.filter(item => item.CRITICALITY === 'Medium').length;
        const hiCritCount = data.filter(item => item.CRITICALITY === 'High').length;
        const criticalCritCount = data.filter(item => item.CRITICALITY === 'Critical').length;

        setTotalCount(data.length);
        setOpenCount(openStatusCount);
        setClosedCount(closedStatusCount);
        setResolvedCount(resResoCount);
        setPendingCount(pendResoCount);
        setUnresolvedCount(unresResoCount);
        setAssyCount(assyTypeCount);
        setNonAssyCount(nonAssyTypeCount);
        setBillingCount(billingEscalCount);
        setExecutiveCount(executiveEscalCount);
        setReductionCount(reductionEscalCount);
        setPerformanceCount(performanceEscalCount);
        setLeadershipCount(leadershipEscalCount);
        setOutputCount(outputEscalCount);
        setTermCount(termEscalCount);
        setIssuesCount(issuesEscalCount);
        setOthersCount(othersEscalCount);
        setCritLowCount(lowCritCount);
        setCritMidCount(midCritCount);
        setCritHiCount(hiCritCount);
        setCritCritCount(criticalCritCount);
        

        // Update escalation type data for PieChart
        setEscalationData([
          { name: 'Billing Issue', value: data.filter(item => item.ESCALATIONTYPE === 'Billing Issue').length },
          { name: 'Executive Escalation', value: data.filter(item => item.ESCALATIONTYPE === 'Executive Escalation').length },
          { name: 'Headcount Reduction', value: data.filter(item => item.ESCALATIONTYPE === 'Headcount Reduction').length },
          { name: 'Performance Issue', value: data.filter(item => item.ESCALATIONTYPE === 'Performance Issue').length },
          { name: 'Leadership Concerns', value: data.filter(item => item.ESCALATIONTYPE === 'Leadership Concerns').length },
          { name: 'Task / Output Feedback', value: data.filter(item => item.ESCALATIONTYPE === 'Task / Output Feedback').length },
          { name: 'Termination of Service', value: data.filter(item => item.ESCALATIONTYPE === 'Termination of Service').length },
          { name: 'Unresolved Issues', value: data.filter(item => item.ESCALATIONTYPE === 'Unresolved Issues').length },
          { name: 'Others', value: data.filter(item => item.ESCALATIONTYPE === 'Others').length },
        ]);

        // Update escalation type data for PieChart
        setCriticalityData([
          { name: 'Low', value: data.filter(item => item.CRITICALITY === 'Low').length },
          { name: 'Medium', value: data.filter(item => item.CRITICALITY === 'Medium').length },
          { name: 'High', value: data.filter(item => item.CRITICALITY === 'High').length },
          { name: 'Critical', value: data.filter(item => item.CRITICALITY === 'Critical').length },
        ]);

      })
      .catch(error => console.error('Error fetching data:', error));
      }, []);

  const categories = escalationData.map((item, index) => ({
    name: `${item.name} (${((item.value / escalCount) * 100).toFixed(2)}%)`,
    color: colors[index]
  }));

  const critcategories = criticalityData.map((item, index) => ({
    name: `${item.name} (${((item.value / escalCount) * 100).toFixed(2)}%)`,
    color: colors[index]
  }));

  const handleSearchQueryChange = (e) => setSearchQuery(e.target.value);

  useEffect(() => {
    const filteredData = rows.filter(row => {
      return Object.values(row).some(value =>
        value && value.toString().toLowerCase().includes(searchQuery.toLowerCase())
      );
    });
    setFilteredRows(filteredData);
  }, [searchQuery, rows]);

  const handleSort = (columnKey) => {
    let direction = 'ascending';
    if (sortConfig.key === columnKey && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key: columnKey, direction });

    const sortedData = [...filteredRows].sort((a, b) => {
      const aValue = a[columnKey];
      const bValue = b[columnKey];
      
      if (aValue < bValue) return direction === 'ascending' ? -1 : 1;
      if (aValue > bValue) return direction === 'ascending' ? 1 : -1;
      return 0;
    });

    setFilteredRows(sortedData);
  };

  const handleDownload = () => {
    const worksheet = XLSX.utils.json_to_sheet(filteredRows);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Escalations");
    
    XLSX.writeFile(workbook, "Client_Escalations.xlsx");
  };

  const handleDoubleClick = (client) => {
    navigate('/UpdateEscalationInfo', { state: { escalationData: client } });
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const handleAddEscalationData = () => {
    navigate('/AddEscalationInfo');
  };

  return (
    <div className="sidebar-and-main-container">
      <Sidebar />

      <div className="main-content">
        <header
          className="dashboard-header"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '20px'
          }}
        >
          <h1 style={{ fontWeight: 'normal', fontSize: '20px', margin: '0' }}>Client Escalations</h1>

          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div className="user-info" style={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>
              <img
                src={profilePlaceholderIcon}
                alt="Profile Placeholder Icon"
                style={{ width: '25px', height: '25px', marginRight: '10px' }}
              />
              <span style={{ marginRight: '10px', fontWeight: 'normal' }}>USER: {userid}</span>
            </div>

            <button
              className="logout-button"
              onClick={() => setIsLogoutModalVisible(true)}
              style={{
                background: 'none',
                border: 'none',
                cursor: 'pointer',
                padding: '0',
                display: 'flex',
                alignItems: 'center',
                marginRight: '10px'
              }}
            >
              <img src={logoutIcon} alt="Logout Icon" style={{ width: '20px', height: '20px' }} />
            </button>
          </div>
        </header>

        {/* Escalation Dashboard */}
        <div style={{ display: 'flex', alignItems: 'left', paddingLeft: '30px', paddingTop: '20px', gap:'10px'}}>
        <div style={{ 
            width: '180px',  
            height: '240px',  
            textAlign: 'center', 
            backgroundColor: 'white', 
            color: '#253E88', 
            padding: '20px', 
            borderRadius: '8px', 
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)' // Add shadow here
          }}>
            <h4 style={{ marginBottom: '30px', marginTop: '-5px', fontSize: '14px', fontWeight: 'bold' }}>Closed Escalations %</h4>
            <CircularProgressbar 
              value={(closedCount / (totalCount)) * 100} 
              text={`${((closedCount / totalCount) * 100).toFixed(2)}%`}   
              styles={{
                path: {
                  stroke: (() => {
                    const value = (closedCount / totalCount) * 100;
                    if (value === 100) {
                      return '#00b300'; // Green
                    } else if (value >= 90 && value < 100) {
                      return '#e87902'; // Orange
                    } else {
                      return '#e80202'; // Red
                    }
                  })(),
                  strokeLinecap: 'round', // Customize the shape of the ends of the progress bar
                },
                text: {
                  fill: (() => {
                    const value = (closedCount / totalCount) * 100;
                    if (value === 100) {
                      return '#00b300'; // Green
                    } else if (value >= 90 && value < 100) {
                      return '#e87902'; // Orange
                    } else {
                      return '#e80202'; // Red
                    }
                  })(),
                  fontSize: '16px', // Customize the text size
                },
                trail: {
                  stroke: '#d6d6d6', // Customize the trail color
                },
              }} 
            />
          </div>

          <div style={{ 
            height: '240px',
            width: '180px', 
            padding: '0px', 
            borderRadius: '8px', 
            textAlign: 'center',  
            gap: '5px',
            backgroundColor: 'white',
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)', 
            display: 'flex', 
            flexDirection: 'column', 
            justifyContent: 'center', 
            alignItems: 'center' 
          }}>
          <div style={{ width: 'fit-content', padding: '10px', textAlign: 'center', fontSize: '14px', borderRadius: '8px'}}>
            <h1 style={{color: 'black', marginBottom:'5px'}}>{totalCount}</h1>
            <h4>Total Escalations</h4>
          </div>
          <div style={{ width: 'fit-content', padding: '10px', textAlign: 'center', fontSize: '14px', borderRadius: '8px'}}>
            <h1 style={{color: 'red', marginBottom:'5px'}}>{openCount}</h1>
            <h4>Active Escalations</h4>
          </div>
          <div style={{ width: 'fit-content', padding: '10px', textAlign: 'center', fontSize: '14px', borderRadius: '8px'}}>
            <h1 style={{color: '#253E88', marginBottom:'5px'}}>{closedCount}</h1>
            <h4> Closed Escalations</h4>
          </div>
        </div>

        <div style={{ 
            width: '180px',  
            height: '240px',  
            textAlign: 'center', 
            backgroundColor: 'white', 
            color: '#253E88', 
            padding: '20px', 
            borderRadius: '8px', 
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)' // Add shadow here
          }}>
            <h4 style={{ marginBottom: '30px', marginTop: '-5px', fontSize: '14px', fontWeight: 'bold'  }}>Resolved Rate %</h4>
            <CircularProgressbar 
              value={(resolvedCount / totalCount) * 100} 
              text={`${((resolvedCount / totalCount) * 100).toFixed(2)}%`}   
              styles={{
                path: {
                  stroke: (() => {
                    const value = (closedCount / totalCount) * 100;
                    if (value === 100) {
                      return '#00b300'; // Green
                    } else if (value >= 90 && value < 100) {
                      return '#e87902'; // Orange
                    } else {
                      return '#e80202'; // Red
                    }
                  })(),
                  strokeLinecap: 'round', // Customize the shape of the ends of the progress bar
                },
                text: {
                  fill: (() => {
                    const value = (closedCount / totalCount) * 100;
                    if (value === 100) {
                      return '#00b300'; // Green
                    } else if (value >= 90 && value < 100) {
                      return '#e87902'; // Orange
                    } else {
                      return '#e80202'; // Red
                    }
                  })(),
                  fontSize: '16px', // Customize the text size
                },
                trail: {
                  stroke: '#d6d6d6', // Customize the trail color
                },
              }} 
            />
          </div>
        
          <div style={{ 
            height: '240px',
            width: '180px', 
            padding: '0px', 
            borderRadius: '8px', 
            textAlign: 'center',  
            gap: '5px',
            backgroundColor: 'white',
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)', 
            display: 'flex', 
            flexDirection: 'column', 
            justifyContent: 'center', 
            alignItems: 'center' 
          }}>
          <div style={{ width: 'fit-content', padding: '10px', textAlign: 'center', fontSize: '14px', borderRadius: '8px'}}>
            <h1 style={{color: '#253E88', marginBottom:'5px'}}>{resolvedCount}</h1>
            <h4>Resolved</h4>
          </div>
          <div style={{ width: 'fit-content', padding: '10px', textAlign: 'center', fontSize: '14px', borderRadius: '8px'}}>
            <h1 style={{color: 'red', marginBottom:'5px'}}>{unresolvedCount}</h1>
            <h4>Unresolved</h4>
          </div>
          <div style={{ width: 'fit-content', padding: '10px', textAlign: 'center', fontSize: '14px', borderRadius: '8px'}}>
            <h1 style={{color: 'orange', marginBottom:'5px'}}>{pendingCount}</h1>
            <h4>Pending Resolution</h4>
          </div>
        </div>

        {/* Escalation Type */}
        <div style={{
            width: '530px',
            height: '240px', // Increased height to accommodate the title
            padding: '10px',
            borderRadius: '8px',
            backgroundColor: 'white',
            textAlign: 'center',
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
        }}>
            {/* Title */}
            <div style={{
                fontSize: '18px',
                fontWeight: 'bold',
                marginBottom: '10px'
            }}>
                Breakdown by Escalation Type
            </div>

            {/* Content */}
            <div style={{
                display: 'flex', // Flex layout for two columns
                alignItems: 'center', // Center items vertically
                justifyContent: 'space-between', // Space between columns
                gap:'20px'
            }}>
                <div style={{ flex: '1', display: 'flex', justifyContent: 'center' }}> {/* Left Column for PieChart */}
                    <PieChart width={180} height={180}>
                        <Pie
                            data={escalationData}
                            dataKey="value"
                            nameKey="name"
                            cx="50%"
                            cy="50%"
                            outerRadius={90} // Adjusted to fit container
                            fill="#8884d8"
                        >
                            {escalationData.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                            ))}
                        </Pie>
                        <Tooltip />
                    </PieChart>
                </div>

                <div style={{ flex: '1', textAlign: 'left' }}> {/* Right Column for Legend */}
                    <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
                        {categories.map((category, index) => (
                            <li key={`legend-item-${index}`} style={{ color: category.color, marginBottom: '5px' }}>
                                {category.name}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
          </div>


      {/* Criticality */}
          <div style={{
            width: '350px',
            height: '240px', // Increased height to accommodate the title
            padding: '10px',
            borderRadius: '8px',
            backgroundColor: 'white',
            textAlign: 'center',
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
        }}>
            {/* Title */}
            <div style={{
                fontSize: '18px',
                fontWeight: 'bold',
                marginBottom: '10px'
            }}>
                Breakdown by Criticality
            </div>

            {/* Content */}
            <div style={{
                display: 'flex', // Flex layout for two columns
                alignItems: 'center', // Center items vertically
                justifyContent: 'space-between', // Space between columns
                gap:'20px'
            }}>
                <div style={{ flex: '1', display: 'flex', justifyContent: 'center' }}> {/* Left Column for PieChart */}
                    <PieChart width={180} height={180}>
                        <Pie
                            data={criticalityData}
                            dataKey="value"
                            nameKey="name"
                            cx="50%"
                            cy="50%"
                            outerRadius={90} // Adjusted to fit container
                            fill="#8884d8"
                        >
                            {criticalityData.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                            ))}
                        </Pie>
                        <Tooltip />
                    </PieChart>
                </div>

                <div style={{ flex: '1', textAlign: 'left' }}> {/* Right Column for Legend */}
                    <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
                        {critcategories.map((category, index) => (
                            <li key={`legend-item-${index}`} style={{ color: category.color, marginBottom: '5px' }}>
                                {category.name}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
          </div>

        </div>
        <div>
          <div className="search-bar-section">
            <input
              type="text"
              placeholder="Search"
              value={searchQuery}
              onChange={handleSearchQueryChange}
              className="search-input"
              style={{
                backgroundImage: `url(${searchIcon})`,
                backgroundSize: '17px',
                backgroundPosition: '10px center',
                backgroundRepeat: 'no-repeat',
                paddingLeft: '40px',
                marginTop: '60px'
              }}
            />
          </div>

          <div className="button-section">
            <button className="btn reload-btn" onClick={() => { window.location.reload() }}>
              <IoMdRefresh className="reload-btnicon" />
            </button>  

            {UserService.isAdmin() && 
            <button className="btn add-btn" onClick={handleAddEscalationData}>
              <img src={addIcon} alt="Add Icon" className="icon" /> 
              Add New
            </button>}

            {UserService.isAdmin() && 
            <button className="btn download-btn" onClick={handleDownload}>
              <img src={downloadIcon} alt="Download Icon" className="icon" /> 
            </button>}
          </div>
        </div>

        <div className="table-section" ref={tableRef}>
          <table>
            <thead>
              <tr>
                <th onClick={() => handleSort('ESCALATION_DATE')}>Escalation Date</th>
                <th onClick={() => handleSort('ACCOUNT')}>Account</th>
                <th onClick={() => handleSort('ESCALATIONTYPE')}>Escalation Type</th>
                <th onClick={() => handleSort('CRITICALITY')}>Criticality</th>
                <th onClick={() => handleSort('OIC')}>OIC</th>
                <th onClick={() => handleSort('RESOLUTIONSTATUS')}>Resolution Status</th>
                <th onClick={() => handleSort('STATUS')}>Escalation Status</th>
                <th onClick={() => handleSort('RESOLVEDDATE')}>Date Closed</th>
              </tr>
            </thead>
          </table>

          <div className="table-body">
            <table>
              <tbody>
                {filteredRows.length > 0 ? (
                  filteredRows.map((row) => (
                    <tr 
                    key={row.ID || row.ESCALATIONID }
                    onClick={() => setSelectedRow(row)}
                    onDoubleClick={() => handleDoubleClick(row)}
                    className={selectedRow?.ID === row.ID ? 'selected-row' : ''}
                  >
                      <td>{formatDate(row.ESCALATION_DATE)}</td>
                      <td>{row.ACCOUNT}</td>
                      <td>{row.ESCALATIONTYPE}</td>
                      <td>{row.CRITICALITY}</td>
                      <td>{row.OIC}</td>
                      <td>{row.RESOLUTIONSTATUS}</td>
                      <td>{row.STATUS}</td>
                      <td>{row.RESOLVEDDATE ? formatDate(row.RESOLVEDDATE) : ""}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="8">No data available</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>

        {isLogoutModalVisible && (
          <div className="overlay" onClick={() => setIsLogoutModalVisible(false)}>
            <div className="modal" onClick={(e) => e.stopPropagation()}>
              <h2>Confirm Logout</h2>
              <p>Are you sure you want to log out?</p>
              <button className="mdlcreate-button" onClick={() => {
                localStorage.clear()
                navigate('/login')}}>Logout</button>
              <button className="mdlclose-button" onClick={() => setIsLogoutModalVisible(false)}>Cancel</button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Escalations;
