import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import * as XLSX from 'xlsx';
import './Escalations.css';
import Sidebar from '../../common/Sidebar.jsx'; 
import { IoMdRefresh } from "react-icons/io";
import axios from 'axios';
import UserService from '../../service/UserService.js';
import UserProfileModal from "../Profile/ProfilePage.jsx";
import profilePlaceholderIcon from '../../../assets/profile_placeholder.png';
import logoutIcon from '../../../assets/logout_icon.png';
import addIcon from '../../../assets/add_icon.png';
import searchIcon from '../../../assets/search_symbol.png';
import downloadIcon from '../../../assets/download_icon.png';
import reloadIcon from '../../../assets/reload_icon.png'; 
import { SERVER_URL } from '../../lib/constants.js';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { PieChart, Pie, Cell, Tooltip, Legend } from 'recharts';

const Escalations = () => {
  const navigate = useNavigate(); 
  const tableRef = useRef(null);
  const [userid, setUserId] = useState('');
  const [userName, setUserName] = useState('');
  const [isLogoutModalVisible, setIsLogoutModalVisible] = useState(false);
  const [rows, setRows] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [filteredRows, setFilteredRows] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  
  // Define your dashboard-related states here
  const [totalCount, setTotalCount] = useState(0);
  const [openCount, setOpenCount] = useState(0);
  const [closedCount, setClosedCount] = useState(0);
  const [resolvedCount, setResolvedCount] = useState(0);
  const [pendingCount, setPendingCount] = useState(0);
  const [unresolvedCount, setUnresolvedCount] = useState(0);
  const [assyCount, setAssyCount] = useState(0);
  const [nonAssyCount, setNonAssyCount] = useState(0);
  const [billingCount, setBillingCount] = useState(0);
  const [executiveCount, setExecutiveCount] = useState(0);
  const [reductionCount, setReductionCount] = useState(0);
  const [performanceCount, setPerformanceCount] = useState(0);
  const [leadershipCount, setLeadershipCount] = useState(0);
  const [outputCount, setOutputCount] = useState(0);
  const [termCount, setTermCount] = useState(0);
  const [issuesCount, setIssuesCount] = useState(0);
  const [othersCount, setOthersCount] = useState(0);
  const [critLowCount, setCritLowCount] = useState(0);
  const [critMidCount, setCritMidCount] = useState(0);
  const [critHiCount, setCritHiCount] = useState(0);
  const [critCritCount, setCritCritCount] = useState(0);


  const [escalationData, setEscalationData] = useState([
    { name: 'Billing Issues', value: billingCount },
    { name: 'Executive Escalation', value: executiveCount },
    { name: 'Headcount Reduction', value: reductionCount },
    { name: 'Performance Issue', value: performanceCount },
    { name: 'Leadership Concerns', value: leadershipCount },
    { name: 'Task / Output Feedback', value: outputCount },
    { name: 'Termination of Service', value: termCount },
    { name: 'Unresolved Issues', value: issuesCount },
    { name: 'Others', value: othersCount },
  ]);

  const [criticalityData, setCriticalityData] = useState([
    { name: 'Low', value: critLowCount },
    { name: 'Medium', value: critMidCount },
    { name: 'High', value: critHiCount },
    { name: 'Critical', value: critCritCount },
  ]);

  const escalCount = escalationData.reduce((acc, item) => acc + item.value, 0);

  const colors = ['#1F618D', '#5D6D7E', '#E74C3C', '#FF0000', '#2874A6', '#2E86C1', '#34495E', '#2C3E50', '#1A5276'];

  useEffect(() => {
    const storedUserId = localStorage.getItem('userid');
    const storedUserName = localStorage.getItem('name');
    setUserId(storedUserId);
    setUserName(storedUserName);
  }, []);

  useEffect(() => {
    fetch(`${SERVER_URL}/api/escalations`,{
      method:"GET",
      headers:{
        userid:localStorage.getItem("userid"),
        role:localStorage.getItem("role")
      }
    })
      .then(response => response.json())
      .then(data => {
        setRows(data);
        setFilteredRows(data);

        const openStatusCount = data.filter(item => item.STATUS === 'Open').length;
        const closedStatusCount = data.filter(item => item.STATUS === 'Closed').length;
        const resResoCount = data.filter(item => item.RESOLUTIONSTATUS === 'Resolved').length;
        const pendResoCount = data.filter(item => item.RESOLUTIONSTATUS === '').length;
        const unresResoCount = data.filter(item => item.RESOLUTIONSTATUS === 'Unresolved').length;
        const assyTypeCount = data.filter(item => item.CLIENTCATEGORY === 'Assembly').length;
        const nonAssyTypeCount = data.filter(item => item.CLIENTCATEGORY === 'Non Assembly').length;
        const billingEscalCount = data.filter(item => item.ESCALATIONTYPE === 'Billing Issue').length;
        const executiveEscalCount = data.filter(item => item.ESCALATIONTYPE === 'Executive Escalation').length;
        const reductionEscalCount = data.filter(item => item.ESCALATIONTYPE === 'Headcount Reduction').length;
        const performanceEscalCount = data.filter(item => item.ESCALATIONTYPE === 'Performance Issue').length;
        const leadershipEscalCount = data.filter(item => item.ESCALATIONTYPE === 'Leadership Concerns').length;
        const outputEscalCount = data.filter(item => item.ESCALATIONTYPE === 'Task / Output Feedback').length;
        const termEscalCount = data.filter(item => item.ESCALATIONTYPE === 'Termination of Service').length;
        const issuesEscalCount = data.filter(item => item.ESCALATIONTYPE === 'Unresolved Issues').length;
        const othersEscalCount = data.filter(item => item.ESCALATIONTYPE === 'Others').length;
        const lowCritCount = data.filter(item => item.CRITICALITY === 'Low').length;
        const midCritCount = data.filter(item => item.CRITICALITY === 'Medium').length;
        const hiCritCount = data.filter(item => item.CRITICALITY === 'High').length;
        const criticalCritCount = data.filter(item => item.CRITICALITY === 'Critical').length;

        setTotalCount(data.length);
        setOpenCount(openStatusCount);
        setClosedCount(closedStatusCount);
        setResolvedCount(resResoCount);
        setPendingCount(pendResoCount);
        setUnresolvedCount(unresResoCount);
        setAssyCount(assyTypeCount);
        setNonAssyCount(nonAssyTypeCount);
        setBillingCount(billingEscalCount);
        setExecutiveCount(executiveEscalCount);
        setReductionCount(reductionEscalCount);
        setPerformanceCount(performanceEscalCount);
        setLeadershipCount(leadershipEscalCount);
        setOutputCount(outputEscalCount);
        setTermCount(termEscalCount);
        setIssuesCount(issuesEscalCount);
        setOthersCount(othersEscalCount);
        setCritLowCount(lowCritCount);
        setCritMidCount(midCritCount);
        setCritHiCount(hiCritCount);
        setCritCritCount(criticalCritCount);
        

        // Update escalation type data for PieChart
        setEscalationData([
          { name: 'Billing Issue', value: data.filter(item => item.ESCALATIONTYPE === 'Billing Issue').length },
          { name: 'Executive Escalation', value: data.filter(item => item.ESCALATIONTYPE === 'Executive Escalation').length },
          { name: 'Headcount Reduction', value: data.filter(item => item.ESCALATIONTYPE === 'Headcount Reduction').length },
          { name: 'Performance Issue', value: data.filter(item => item.ESCALATIONTYPE === 'Performance Issue').length },
          { name: 'Leadership Concerns', value: data.filter(item => item.ESCALATIONTYPE === 'Leadership Concerns').length },
          { name: 'Task / Output Feedback', value: data.filter(item => item.ESCALATIONTYPE === 'Task / Output Feedback').length },
          { name: 'Termination of Service', value: data.filter(item => item.ESCALATIONTYPE === 'Termination of Service').length },
          { name: 'Unresolved Issues', value: data.filter(item => item.ESCALATIONTYPE === 'Unresolved Issues').length },
          { name: 'Others', value: data.filter(item => item.ESCALATIONTYPE === 'Others').length },
        ]);

        // Update escalation type data for PieChart
        setCriticalityData([
          { name: 'Low', value: data.filter(item => item.CRITICALITY === 'Low').length },
          { name: 'Medium', value: data.filter(item => item.CRITICALITY === 'Medium').length },
          { name: 'High', value: data.filter(item => item.CRITICALITY === 'High').length },
          { name: 'Critical', value: data.filter(item => item.CRITICALITY === 'Critical').length },
        ]);

      })
      .catch(error => console.error('Error fetching data:', error));
      }, []);

  const categories = escalationData.map((item, index) => ({
    name: `${item.name} (${((item.value / escalCount) * 100).toFixed(2)}%)`,
    color: colors[index]
  }));

  const critcategories = criticalityData.map((item, index) => ({
    name: `${item.name} (${((item.value / escalCount) * 100).toFixed(2)}%)`,
    color: colors[index]
  }));

  const handleSearchQueryChange = (e) => setSearchQuery(e.target.value);

  useEffect(() => {
    const filteredData = rows.filter(row => {
      return Object.values(row).some(value =>
        value && value.toString().toLowerCase().includes(searchQuery.toLowerCase())
      );
    });
    setFilteredRows(filteredData);
  }, [searchQuery, rows]);

  const handleSort = (columnKey) => {
    let direction = 'ascending';
    if (sortConfig.key === columnKey && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key: columnKey, direction });

    const sortedData = [...filteredRows].sort((a, b) => {
      const aValue = a[columnKey];
      const bValue = b[columnKey];
      
      if (aValue < bValue) return direction === 'ascending' ? -1 : 1;
      if (aValue > bValue) return direction === 'ascending' ? 1 : -1;
      return 0;
    });

    setFilteredRows(sortedData);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.user-info-wrapper')) {
        setIsHovered(false);
      }
    };
  
    document.addEventListener('mousedown', handleClickOutside);
  
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleDownload = () => {
    const worksheet = XLSX.utils.json_to_sheet(filteredRows);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Escalations");
    
    XLSX.writeFile(workbook, "Client_Escalations.xlsx");
  };

  const handleDoubleClick = (client) => {
    navigate('/UpdateEscalationInfo', { state: { escalationData: client } });
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const handleAddEscalationData = () => {
    navigate('/AddEscalationInfo');
  };

  //User navigation
  const [isHovered, setIsHovered] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false); // Tracks if the process is ongoing

  const generateOTP = () => Math.floor(100000 + Math.random() * 900000); // Generate a 6-digit OTP

  const formatDateTime = (date) => {
    const options = { hour: '2-digit', minute: '2-digit', second: '2-digit' };
    return date.toLocaleTimeString(undefined, options);
  };

  // Personalize Password
  const handleNavigateWithOTP = async (email) => {
    if (isProcessing) return; // Prevent navigation if already processing
  
    setIsProcessing(true); // Set loading state to true
  
    // Generate OTP and calculate date/time
    const otp = generateOTP();
    const now = new Date();
    const expiryDateTime = new Date(now.getTime() + 5 * 60 * 1000); // 5 minutes later
  
    const formattedRequestedDateTime = formatDateTime(now);
    const formattedExpiryDateTime = formatDateTime(expiryDateTime);
  
    try {
      // Send POST request to server
      const response = await fetch(`${SERVER_URL}/pwResetOTP`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          emailAddress: userid,
          otp,
          requestedDateTime: formattedRequestedDateTime,
          expiryDateTime: formattedExpiryDateTime,
        }),
      });
  
      if (!response.ok) {
        throw new Error(`Failed to send OTP: ${response.statusText}`);
      }
  
      // Proceed with navigation on success
      navigate('/OTP-SECURE', {
        state: {
          emailAddress: email,
          otp,
          requestedDateTime: formattedRequestedDateTime,
          expiryDateTime: formattedExpiryDateTime,
        },
      });
    } catch (error) {
      console.error('Error sending OTP:', error);
      alert('Failed to send OTP. Please try again.');
    } finally {
      setIsProcessing(false); // Reset loading state
    }
  };

  const infoCardStyle = {
    width: '180px',
    height: '240px',
    textAlign: 'center',
    backgroundColor: 'white',
    color: '#253E88',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
    flex: '1 1 calc(25% - 20px)', // Allows 4 cards per row
    maxWidth: '180px', // Prevents cards from stretching
  };
  
  const chartContainerStyle = {
    width: '250px',
    textAlign: 'center',
    backgroundColor: 'white',
    borderRadius: '8px',
    padding: '10px',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
    flex: '1 1 calc(50% - 20px)', // Allows 2 charts per row
    maxWidth: '250px',
  };
  
  const cardHeaderStyle = {
    marginBottom: '15px',
    fontSize: '14px',
    fontWeight: 'bold',
  };
  
  const progressBarStyles = {
    path: {
      stroke: '#00b300', // Color for progress bar
      strokeLinecap: 'round',
    },
    text: {
      fill: '#00b300', // Color for percentage text
      fontSize: '16px',
    },
    trail: {
      stroke: '#d6d6d6', // Color for progress bar trail
    },
  };

  const chartRowStyle = {
    display: 'flex',
    alignItems: 'center', // Vertically align items
    justifyContent: 'center', // Center align items horizontally
    padding: '20px',
    backgroundColor: 'white',
    borderRadius: '8px',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
    maxHeight:'240px',
  };
  
  const pieChartStyle = {
    flex: '1',
    display: 'flex',
    justifyContent: 'center',
    padding:'10dp',
  };
  
  const legendStyle = {
    flex: '1',
    listStyleType: 'none',
    padding: '0',
    marginTop: '25px',
    fontSize: '12px',
    color: '#253E88',
  };

  const [isModalOpen, setModalOpen] = useState(false);
  
  return (
    <div className="sidebar-and-main-container">
      <Sidebar />

      <div className="main-content">
        <header
          className="dashboard-header"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '20px'
          }}
        >
          <h1 style={{ fontWeight: 'normal', fontSize: '20px', margin: '0' }}>Client Escalations</h1>

          <div style={{ display: 'flex', alignItems: 'center' }}>
          <div
              className="user-info-wrapper"
              style={{ position: 'relative', display: 'inline-block' }}
            >
              <div
                className="user-info"
                style={{ display: 'flex', alignItems: 'center', marginRight: '10px', cursor: 'pointer' }}
                onClick={() => setIsHovered((prev) => !prev)} // Toggle dropdown on click
              >
                <img
                  src={profilePlaceholderIcon}
                  alt="Profile Placeholder Icon"
                  style={{ width: '25px', height: '25px', marginRight: '10px' }}
                />
                <span style={{ marginRight: '10px', fontWeight: 'bold' }}>{userName}</span>
              </div>

              {isHovered && (
                <div className="user-options">
                  {/* My Profile with Modal */}
                  <div
                    className="user-option"
                    onClick={() => setModalOpen(true)} // Opens the modal
                  >
                    My Profile
                  </div>

                  {/* Change Password Option */}
                  <div
                    className={`user-option ${isProcessing ? 'disabled' : ''}`}
                    onClick={() => !isProcessing && handleNavigateWithOTP(userid)}
                    style={{
                      pointerEvents: isProcessing ? 'none' : 'auto',
                      opacity: isProcessing ? 0.6 : 1,
                    }}
                  >
                    {isProcessing ? 'Requesting...' : 'Change Password'}
                  </div>

                  {/* User Profile Modal */}
                  <UserProfileModal isOpen={isModalOpen} onClose={() => setModalOpen(false)} />
                </div>
              )}
            </div>

            <button
              className="logout-button"
              onClick={() => setIsLogoutModalVisible(true)}
              style={{
                background: 'none',
                border: 'none',
                cursor: 'pointer',
                padding: '0',
                display: 'flex',
                alignItems: 'center',
                marginRight: '10px'
              }}
            >
              <img src={logoutIcon} alt="Logout Icon" style={{ width: '20px', height: '20px' }} />
            </button>
          </div>
        </header>

        <div className= "escalations-content" >

        <div className="dashboard-container" style= {{ marginLeft: '-10px', overflowX: 'auto'}} >
        {/* Escalation Dashboard */}
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap', // Allow items to wrap into new rows
            gap: '20px', // Add spacing between items
            justifyContent: 'center', // Center-align items
            alignItems: 'flex-start', // Align items at the top
            padding: '20px', // Add padding inside the container
          }}
        >
          {/* Info Card 1 */}
          <div style={infoCardStyle}>
            <h4 style={cardHeaderStyle}>Closed Escalations %</h4>
            <CircularProgressbar
              value={(closedCount / totalCount) * 100}
              text={`${((closedCount / totalCount) * 100).toFixed(2)}%`}
              styles={progressBarStyles}
            />
          </div>

          {/* Info Card 2 */}
          <div style={infoCardStyle}>
            <h1 style={{ color: 'black' }}>{totalCount}</h1>
            <h4 style={cardHeaderStyle}>Total Escalations</h4>
            <h1 style={{ color: 'red' }}>{openCount}</h1>
            <h4 style={cardHeaderStyle}>Active Escalations</h4>
            <h1 style={{ color: 'blue' }}>{closedCount}</h1>
            <h4 style={cardHeaderStyle}>Closed Escalations</h4>
          </div>

          {/* Info Card 3 */}
            <div style={infoCardStyle}>
            <h4 style={cardHeaderStyle}>Resolved Rate %</h4>
            <CircularProgressbar
              value={(resolvedCount / totalCount) * 100}
              text={`${((resolvedCount / totalCount) * 100).toFixed(2)}%`}
              styles={progressBarStyles}
            />
          </div>

          {/* Info Card 4 */}
          <div style={infoCardStyle}>
            <h1 style={{ color: 'blue' }}>{resolvedCount}</h1>
            <h4 style={cardHeaderStyle}>Resolved</h4>
            <h1 style={{ color: 'red' }}>{unresolvedCount}</h1>
            <h4 style={cardHeaderStyle}>Unresolved</h4>
            <h1 style={{ color: 'yellow' }}>{pendingCount}</h1>
            <h4 style={cardHeaderStyle}>Pending Resolution</h4>
          </div>

          {/* Pie Chart: Escalation Type */}
          
          <div style={chartRowStyle}>
            <div style={{justifyContent: 'center', alignItems: 'center'}}>
              {/* Title */}
              <span style={{
                  fontSize: '14px',
                  fontWeight: 'bold',
                  marginBottom: '10px',
                  color: 'black',
                  justifyContent: 'center', 
                  alignItems: 'center',
              }}>
                  Breakdown by Escalation Type 
              </span>
              <div style={pieChartStyle}>
                <PieChart width={200} height={200}>
                  <Pie
                    data={escalationData}
                    dataKey="value"
                    nameKey="name"
                    cx="50%"
                    cy="50%"
                    outerRadius={80}
                    fill="#8884d8"
                  >
                    {escalationData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                    ))}
                  </Pie>
                  <Tooltip />
                </PieChart>
              </div>
            </div> 
            
            <ul style={legendStyle}>


            {categories
              .map((category, index) => ({
                category,
                value: escalationData[index].value, // Pair category with its value
              }))
              .filter(item => item.value !== 0) // Filter out items where value is 0
              .map((item, filteredIndex) => (
                <li
                  key={`legend-item-${filteredIndex}`}
                  style={{
                    color: colors[filteredIndex % colors.length],
                    marginBottom: '10px',
                  }}
                >
                  {item.category.name}: {item.value}
                </li>
              ))}
            </ul>
          </div>

          {/* Pie Chart: Criticality */}
          <div style={chartRowStyle}>
          <div style={{justifyContent: 'center', alignItems: 'center'}}>
              {/* Title */}
              < div style={{
                  fontSize: '14px',
                  fontWeight: 'bold',
                  color: 'black',
                  justifyContent: 'center', 
                  alignItems: 'center',
              }}>
                  Breakdown by Criticality
              </div>
            <div style={pieChartStyle}>
              <PieChart width={200} height={200}>
                <Pie
                  data={criticalityData}
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  outerRadius={80}
                  fill="#8884d8"
                >
                  {criticalityData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                  ))}
                </Pie>
                <Tooltip />
              </PieChart>
              </div>
            </div>
            <ul style={legendStyle} >

              {critcategories.map((category, index) => (
                <li key={`legend-item-${index}`} style={{ color: colors[index % colors.length], marginBottom: '10px' }}>
                  {category.name}: {criticalityData[index].value}
                </li>
              ))}
            </ul>
          </div>

        </div>
          <div className="search-bar-section">
            <input
              type="text"
              placeholder="Search"
              value={searchQuery}
              onChange={handleSearchQueryChange}
              className="search-input"
              style={{
                backgroundImage: `url(${searchIcon})`,
                backgroundSize: '17px',
                backgroundPosition: '10px center',
                backgroundRepeat: 'no-repeat',
                paddingLeft: '40px',
                marginTop: '60px'
              }}
            />
          </div>

          <div className="button-section">
            <button className="btn reload-btn" onClick={() => { window.location.reload() }}>
              <IoMdRefresh className="reload-btnicon" />
            </button>  

            {UserService.isAdmin() && 
            <button className="btn add-btn" onClick={handleAddEscalationData}>
              <img src={addIcon} alt="Add Icon" className="icon" /> 
              Add New
            </button>}

            {UserService.isAdmin() && 
            <button className="btn download-btn" onClick={handleDownload}>
              <img src={downloadIcon} alt="Download Icon" className="icon" /> 
            </button>}
          </div>
        </div>

        <div className="table-section" ref={tableRef}>
          <table>
            <thead>
              <tr>
                <th onClick={() => handleSort('ESCALATION_DATE')}>Escalation Date</th>
                <th onClick={() => handleSort('ACCOUNT')}>Account</th>
                <th onClick={() => handleSort('ESCALATIONTYPE')}>Escalation Type</th>
                <th onClick={() => handleSort('CRITICALITY')}>Criticality</th>
                <th onClick={() => handleSort('OIC')}>OIC</th>
                <th onClick={() => handleSort('RESOLUTIONSTATUS')}>Resolution Status</th>
                <th onClick={() => handleSort('STATUS')}>Escalation Status</th>
                <th onClick={() => handleSort('RESOLVEDDATE')}>Date Closed</th>
              </tr>
            </thead>
          </table>

          <div className="table-body">
            <table>
              <tbody>
                {filteredRows.length > 0 ? (
                  filteredRows.map((row) => (
                    <tr 
                    key={row.ID || row.ESCALATIONID }
                    onClick={() => setSelectedRow(row)}
                    onDoubleClick={() => handleDoubleClick(row)}
                    className={selectedRow?.ID === row.ID ? 'selected-row' : ''}
                  >
                      <td>{formatDate(row.ESCALATION_DATE)}</td>
                      <td>{row.ACCOUNT}</td>
                      <td>{row.ESCALATIONTYPE}</td>
                      <td>{row.CRITICALITY}</td>
                      <td>{row.OIC}</td>
                      <td>{row.RESOLUTIONSTATUS}</td>
                      <td>{row.STATUS}</td>
                      <td>{row.RESOLVEDDATE ? formatDate(row.RESOLVEDDATE) : ""}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="8">No data available</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>

        {isLogoutModalVisible && (
          <div className="overlay" onClick={() => setIsLogoutModalVisible(false)}>
            <div className="modal" onClick={(e) => e.stopPropagation()}>
              <h2>Confirm Logout</h2>
              <p>Are you sure you want to log out?</p>
              <button className="mdlcreate-button" onClick={() => {
                localStorage.clear()
                navigate('/login')}}>Logout</button>
              <button className="mdlclose-button" onClick={() => setIsLogoutModalVisible(false)}>Cancel</button>
            </div>
          </div>
        )}
      </div>
    </div>
    </div>
  );
};

export default Escalations;
