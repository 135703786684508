import React, {useState} from "react";
import { useNavigate } from "react-router-dom";
import UserService from "../service/UserService";
import './LoginPage.css';

// Import the images from the src/assets folder
import cmxLogo from '../../assets/cmxlogo-removebg-preview.png';
import emailIcon from '../../assets/user_icon.png';  
import passwordIcon from '../../assets/pass_icon.png';  
import showIcon from '../../assets/eye_icon.png';  
import hideIcon from '../../assets/eye_slash_icon.png';  

function LoginPage() {
  const [userid, setUserId] = useState('');  // Changed to userid
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);  
  const [forgotUserId, setForgotUserId] = useState('');
  const navigate = useNavigate();

  const isAdmin = UserService.isAdmin();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await UserService.login(userid, password);

      // Check if login was successful
      if (response.token) {
        localStorage.setItem('token', response.token);
        localStorage.setItem('role', response.role);
        localStorage.setItem('userid', userid);
        localStorage.setItem('pwdType', response.pwdType); // Save the password type

        // Redirect based on password type
        if (response.pwdType === 'temporary') {
          navigate('/change-password');
        } else {
          navigate('/Escalations');
        }
      } else {
        setError(response.message);
      }
    } catch (error) {
      console.error("Login Error:", error.message);
      setError(error.message);
      setTimeout(() => {
        setError('');
      }, 5000);
    }
  };


  const togglePasswordVisibility = () => {
    setShowPassword(prevShowPassword => !prevShowPassword);
  };

  return (
    <div className="login-page-wrapper">
      <div className="login-auth-container">
        <div className="login-form-container">
          <div className="login-logo-container">
            <img src={cmxLogo} alt="Callmax Logo" />
          </div>
          <h1 className="login-title">Login</h1>
          {error && <p className="login-error-message">{error}</p>}
          <form onSubmit={handleSubmit}>
            <div className="login-form-group">
              <div className="login-EmpID-form">
                <label>Employee ID</label>
                <div className="login-input-wrapper">
                  <img src={emailIcon} alt="email icon" className="login-input-icon" />
                  <input 
                    type="text" 
                    value={userid} 
                    onChange={(e) => setUserId(e.target.value)} 
                    required
                  />
                </div>
              </div>

              <div className="login-PW-form">
                <label>Password</label>
                <div className="login-input-wrapper">
                  <img src={passwordIcon} alt="password icon" className="login-input-icon" />
                  <input
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)} 
                    required
                  />
                  <button
                    type="button"
                    className="login-toggle-password-btn"
                    onClick={togglePasswordVisibility}
                  >
                    <img 
                      src={showPassword ? hideIcon : showIcon} 
                      alt={showPassword ? "Hide password" : "Show password"} 
                      className="login-eye-icon"  
                    />
                  </button>
                </div>

            </div>
          </div>
          <button type="submit" className="login-btn" disabled={!userid || !password}>Login</button>
          </form>
        </div>
        
        <div className="login-logo-container-lb">
          <h1 className="login-client-services-title">
            <div>CLIENT</div>
            <div>SERVICES</div>
          </h1>
        </div>
      </div>

      {isModalVisible && (
        <div className="login-modal-overlay">
          <div className="login-modal-content">
            <h2>Forgot Password</h2>
            <p>Please enter your Employee ID to reset your password.</p>
            <input
              type="text"
              placeholder="Enter your Employee ID"
              className="login-modal-input"
              value={forgotUserId}
              onChange={(e) => setForgotUserId(e.target.value)}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default LoginPage;