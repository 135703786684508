import React from 'react';
import { Routes, Route, Navigate, useLocation, useNavigate } from "react-router-dom";
// import Navbar from './components/common/Navbar';
// import FooterComponent from './components/common/Footer';
import routesIcon from './assets/routes_icon.png'; // Routes icon
import LoginPage from './components/authentication/LoginPage';
import ProfilePage from './components/Routes/Profile/ProfilePage';  
import UserService from './components/service/UserService';
import Dashboard from './components/LandingPage/Dashboard'; 
import AddClient from './components/Routes/AddClientFolder/AddClient'; // New AddClient component
import UpdateClient from './components/Routes/UpdateClientFolder/UpdateClient'; // New UpdateClient
import Display from './components/Routes/Display';  
import RegisterPage from './components/authentication/RegisterPage';
import ChangePassword from './components/authentication/ChangePassword';
import ForgotPassword from './components/authentication/ForgotPassword';
import NewPassword from './components/authentication/NewPassword';
import OTP from './components/authentication/OTP';
import Escalations from './components/Routes/EscalationsFolder/Escalations';
import AddEscalationInfo from './components/Routes/EscalationsFolder/AddEscalationInfo';
import UpdateEscalationInfo from './components/Routes/EscalationsFolder/UpdateEscalationInfo';
import AccessDenied from './components/Routes/NoAccess/AccessDenied';


function App() {
  const location = useLocation(); // This should work if wrapped in BrowserRouter
  const navigate = useNavigate();
  // Define paths where you don't want to show Navbar and Footer
  const isLoginPage = location.pathname === '/login' || location.pathname === '/';

  const isAuthenticated = UserService.isAuthenticated(); // Check authentication
  const userRole = UserService.getRole(); // Fetch the user role ('user' or 'admin')


  const handleNavigation = (path) => {
    navigate(path);
  };
  
  console.log(UserService.isAdmin())
  return (
    <div className="App">

  
      <div className="content">
        <Routes>
          {/* Public Routes */}
          <Route exact path="/" element={<LoginPage />} />
          <Route exact path="/login" element={<LoginPage />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/OTP-SECURE" element={<OTP />} />
          <Route path="/CREATE-NEWPASS" element={<NewPassword />} />
          <Route 
            exact 
            path="/change-password" 
            element={
              isAuthenticated && localStorage.getItem('pwdType') === 'temporary' 
                ? <ChangePassword /> 
                : <Navigate to="/login" replace />
            } 
          />
          

            {/* Escalations link (visible to all roles) */}
           

         {/* {isAuthenticated && (
            <>
              <Route path="/Escalations" element={<Escalations />} />
              <Route path="/UpdateEscalationInfo" element={<UpdateEscalationInfo />} />
              <Route exact path="/dashboard" element={!UserService.isAdmin() ? <AccessDenied /> : <Dashboard /> }/>
            </>
          )} */}

          {/* Check if user is authenticated and admin before rendering admin-only routes */}
          {/* {isAuthenticated && userRole !== 'user' &&   ( */}
            <> 
          

          <Route path="/register" element={UserService.isAdmin() ? <RegisterPage /> : <Navigate to="/login" replace />} />
          <Route exact path="/dashboard" element={ isAuthenticated  ? UserService.isAdmin() ? <Dashboard/> : <AccessDenied/> : <Navigate to="/login" replace />} />
          <Route exact path="/Escalations" element={ isAuthenticated  ?  <Escalations/> :  <Navigate to="/login" replace />} />
          <Route exact path="/UpdateEscalationInfo" element={ isAuthenticated  ?  <UpdateEscalationInfo/> : <Navigate to="/login" replace />} />
          <Route exact path="/addClient" element={ isAuthenticated  ? UserService.isAdmin() ? <AddClient/> : <AccessDenied/> : <Navigate to="/login" replace />} />  {/* Route for adding client */}
          <Route exact path="/updateClient" element={ isAuthenticated  ? UserService.isAdmin() ? <UpdateClient/> : <AccessDenied/> : <Navigate to="/login" replace />} /> {/* Route for updating client */}
          <Route exact path="/AddEscalationInfo" element={ isAuthenticated  ? UserService.isAdmin() ? <AddEscalationInfo/> : <AccessDenied/> : <Navigate to="/login" replace />} /> {/* Route for updating client */}
          <Route path="/profile" element={ isAuthenticated  ? UserService.isAdmin() ? <ProfilePage/> : <AccessDenied/> : <Navigate to="/login" replace />} />
          <Route path="/client/display" element={ isAuthenticated  ? UserService.isAdmin() ? <Display/> : <AccessDenied/> : <Navigate to="/login" replace />} />


            </>
          {/* )} */}

        </Routes>
      </div>

    </div>
  );
}

export default App;
