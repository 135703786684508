import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom"; // Added useLocation to retrieve state
import axios from "axios"; // Import Axios for API requests
import './NewPassword.css';
import showIcon from '../../assets/eye_icon.png';  
import hideIcon from '../../assets/eye_slash_icon.png';  
import cmxLogo from '../../assets/cmxlogo-removebg-preview.png';
import changePassVector from '../../assets/change_pass_icon.png';
import { SERVER_URL } from '../lib/constants';

function NewPassword() {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showNewPassword, setShowNewPassword] = useState(false); 
  const [showConfirmPassword, setShowConfirmPassword] = useState(false); 
  const [message, setMessage] = useState('');
  const [email, setEmail] = useState(''); // Email state added

  const navigate = useNavigate();
  const location = useLocation(); // Get state from navigation

  useEffect(() => {
    // Ensure navigation includes email, otherwise redirect
    const emailFromState = location.state?.email;
    if (!emailFromState) {
      navigate('/'); // Redirect if email is not passed
    } else {
      setEmail(emailFromState); // Set email from navigation state
    }
  }, [location.state, navigate]);

  const handlePasswordChange = async () => {
    if (newPassword !== confirmPassword) {
      setMessage("Passwords do not match.");
      return;
    }
  
    try {
      // API call to update the password
      const response = await axios.post(`${SERVER_URL}/newPassword`, {
        email, // Pass the email to identify the user
        password: newPassword, // Send plain password
        pwdType: "permanent",
      });
  
      if (response.data.success) {
        setMessage("Password updated successfully!");
        setTimeout(() => navigate('/'), 2000); // Redirect after success
      } else {
        setMessage("Failed to update password. Please try again.");
      }
    } catch (error) {
      console.error("Error updating password:", error);
      setMessage("An error occurred. Please try again.");
    }
  };

  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
    setMessage(''); // Clear any previous message when typing
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    setMessage(''); // Clear any previous message when typing

    // Check if passwords match
    if (e.target.value && newPassword && e.target.value !== newPassword) {
      setMessage("Passwords do not match.");
    }
  };

  return (
    <div className="change-pass-page-wrapper">
      <div className="change-pass-auth-container">
        <div className="change-pass-form-container">
          <div className="change-pass-logo-container">
            <img src={cmxLogo} alt="CMX Logo" />
          </div>

          <h1 className="change-pass-title" style={{ color: 'black', fontSize: '24px', fontWeight: '800', marginLeft: '20px', marginBottom: '30px' }}>Change Password</h1>
          <p className="change-pass-user-id" style={{ fontSize: '16px', fontWeight: '800', marginLeft:'40px' }}>
            Email: <span className="highlight">{email}</span>
          </p>

          <div className="change-pass-form-group">
            <label style={{ fontWeight: '800' }}>Enter new password:</label>
            <div className="change-pass-input-wrapper">
              <input
                type={showNewPassword ? "text" : "password"}
                value={newPassword}
                onChange={handleNewPasswordChange}
                required
              />
              <button
                type="button"
                className="change-pass-toggle-password-btn"
                onClick={() => setShowNewPassword((prev) => !prev)}
              >
                <img 
                  src={showNewPassword ? hideIcon : showIcon} 
                  alt={showNewPassword ? "Hide password" : "Show password"} 
                  className="change-pass-eye-icon"
                />
              </button>
            </div>
          </div>

          <div className="change-pass-form-group">
            <label style={{ fontWeight: '800' }}>Confirm password:</label>
            <div className="change-pass-input-wrapper">
              <input
                type={showConfirmPassword ? "text" : "password"}
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
                required
              />
              <button
                type="button"
                className="change-pass-toggle-password-btn"
                onClick={() => setShowConfirmPassword((prev) => !prev)}
              >
                <img 
                  src={showConfirmPassword ? hideIcon : showIcon} 
                  alt={showConfirmPassword ? "Hide password" : "Show password"} 
                  className="change-pass-eye-icon"
                />
              </button>
            </div>
          </div>

          {message && (
            <p className={message.toLowerCase().includes("successfully") ? "change-pass-message" : "change-pass-error"}>
              {message}
            </p>
          )}

          <button 
            className="change-pass-btn" 
            onClick={handlePasswordChange} 
            disabled={newPassword !== confirmPassword || !newPassword}
          >
            Update Password
          </button>

        </div>

        <div className="change-pass-task-tracker-icon-container">
          <img 
            src={changePassVector} 
            alt="changePassVector" 
            style={{ width: '400px', height: 'auto', marginRight: '40px' }}
          />
        </div>
      </div>
    </div>
  );
}

export default NewPassword;
